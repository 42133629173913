import { CSSProperties, FunctionComponent } from 'react';
import classnames from 'classnames';

type PropsType = {
  centerText?: boolean;
  style?: CSSProperties;
  className?: string;
  children?: React.ReactNode;
};

export const Container: FunctionComponent<PropsType> = ({
  style,
  className,
  centerText,
  children,
}) => {
  return (
    <div
      className={classnames('container',  className, {
        'center-text': centerText,
      })}
      style={style}
    >
      {children}
    </div>
  );
};
