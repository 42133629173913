import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Image } from '../../pieces/image';

import { BlogEntryHeader } from '../blog-entry-header';
import { BlogEntryParagraphBox } from '../blog-entry-paragraph-box';

import headerIcon from './icon.png';
import maniacMansionImage from './maniac-mansion-hamster.png';
import curryDirectionImage from './curry-directions.jpg';
import contraDifferencesImage from './contra-differences.png';
import animalCrossingFetchQuestImage from './animal-crossing-fetch-quest-web.png';
import ocarinaPlayerNamesImage from './ocarina-of-time-player-names-web.png';
import { WebLink } from '../../pieces/web-link';

export const Localization: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Localization | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <BlogEntryHeader
          iconPath={headerIcon}
          title="Localization"
          description="Localization is a great way to expand your audience. Here are some strategies I use to localize text and images."
          dateString="8/29/2014"
        />
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          When you hear the word "money", what is the first thing that comes to mind? Is it a green rectangle of paper with a former US president's face? Maybe it's a coin with Queen Elizabeth's face on one side? How about "breakfast" or "person"? The difference could be where you were born and how you were raised. Updating a game or program so that is adapts itself to the language and country it is executed in is called localization. Localization is important to expanding the possible user base for your game. I will cover how I updated my game engine to adapt to different languages and countries.
        </BlogEntryParagraphBox>
      </Container>

      <Container centerText>
        <Image
          className="blog-entry-image"
          src={maniacMansionImage}
          caption="Maniac Mansion allowed the player to put a hamster into a microwave in some regions."
        />
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          Supporting multiple languages means more than just having a word-for-word replacement. There are expressions like "<span style={{ fontStyle: 'italic' }}>money for old rope</span>" that will lose meaning if you try to translate explicitly. Whether it is large blocks of text or just the label on a button, we will need the ability to replace text to ensure that the meaning isn't lost. This is where localization keys come in. Let's say we have a button that starts a race and we want it to display "<span style={{ fontStyle: 'italic' }}>Go</span>" in English. Since that word will be something different in French then we should use something other than "<span style={{ fontStyle: 'italic' }}>Go</span>" to begin with but it will be replaced with "<span style={{ fontStyle: 'italic' }}>Go</span>" when displayed for English. We will use a localization key for what will represent "<span style={{ fontStyle: 'italic' }}>Go</span>" in English. The key should be something that you can easily differentiate from common text. A good key for the text "<span style={{ fontStyle: 'italic' }}>Go</span>" could be "<span style={{ fontStyle: 'italic' }}>[LS_195]</span>" because you are very unlikely to naturally see that sequence of characters appear. You have to be careful if you open your text system to having replacements. If a user just so happens to create a profile with the name "<span style={{ fontStyle: 'italic' }}>[LS_195]</span>" then you would not want their name to display as "<span style={{ fontStyle: 'italic' }}>Go</span>". It is important to have the ability to skip translating text when appropriate.
        </BlogEntryParagraphBox>
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          Now that we have a key for the word "<span style={{ fontStyle: 'italic' }}>Go</span>" we need to keep in mind that it is also a verb. When translating "<span style={{ fontStyle: 'italic' }}>Go</span>", we wouldn't want the translator to confuse it with the Chinese game Go. The same goes for "<span style={{ fontStyle: 'italic' }}>Close</span>" which could be used as "<span style={{ fontStyle: 'italic' }}>Close the door</span>", "<span style={{ fontStyle: 'italic' }}>Close to work</span>", or "<span style={{ fontStyle: 'italic' }}>two close friends</span>". When translating text, it is important to provide context. There are translation services out there that you can hire but you need to do your best to help them choose the proper translation. I took two Latin classes and three French classes in high school and I found it amazing all the ways that a sentence can change depending on the tense and gender alone. My advice here is to provide any gender info about who is speaking or being spoken to, how it would be used in a sentence for short words or phrases, and character limits.
        </BlogEntryParagraphBox>
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          Character limits are important because a short sentence in one language could be much longer in another language. A character limit can help inform a translator that space is limited and to persuade them to use a shorter synonym where able. We now have localization keys that will give us the language-specific translations. These should be tracked within a spreadsheet or database. You wouldn't want to pay to translate the same text twice.
        </BlogEntryParagraphBox>
      </Container>

      <Container centerText>
        <Image
          className="blog-entry-image"
          src={curryDirectionImage}
          caption="English usually requires more characters than Japanese. From my experience, German can take up to 50% more space than English. It's better to think ahead than to try and squeeze a lot of text into a small space at the last minute."
        />
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          When we localize it would be impractical to try to localize for every permutation of every language and country. This means that in the absence of a perfect localization match then we must compromise with a fallback. Let's say the game supports British English and Canadian French but the user has set their device's language to English and their country to Japan. In this case it would be best to fallback to British English since the user will at least be able to read the text even if it is not perfectly what the user would expect. The selection hierarchy goes: Language-and-Country, Language, then Default. The language identifiers come from ISO 639-1 and the country identifiers come from ISO 3166-1. This means American English is represented by "<span style={{ fontStyle: 'italic' }}>en_US</span>".
        </BlogEntryParagraphBox>
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          If we only translated text that passed through the text renderer then we would miss text that is embedded within images. Since the images are game-specific then it wouldn't make sense to use a localization key when selecting an image to display. For images, I use a different convention. Let's say we have translations for British English and Canadian French and different versions of our title image to match. Let's say the title image's default filename is "<span style={{ fontStyle: 'italic' }}>title.png</span>". If the user has set their device's language to American English then the selection hierarchy will first look for "<span style={{ fontStyle: 'italic' }}>title.en_US.png</span>". If it fails to find that file then it will look for "<span style={{ fontStyle: 'italic' }}>title.en.png</span>" next. If it fails to find that file then it will use the default, which is "<span style={{ fontStyle: 'italic' }}>title.png</span>". This same pattern can be applied to other file types including audio and fonts.
        </BlogEntryParagraphBox>
      </Container>

      <Container centerText>
        <Image
          className="blog-entry-image"
          src={contraDifferencesImage}
          caption="Contra was released as Probotector in Europe. The human characters were replaced with robots to avoid conflicting with laws against selling violent games in Germany."
        />
      </Container>

      <Container className="container">
        <span>Some games contain fetch quests. A fetch quest is an objective to travel to location X, acquire items Y, and deliver them to location Z. A few real-world examples could be:</span>
        <ul>
          <li>Go to the store, buy 6 eggs, and deliver them to your neighbor.</li>
          <li>Visit the dry cleaner, pick up your laundry, and return home with it.</li>
          <li>Take this mail to the post office.</li>
        </ul>
      </Container>

      <Container centerText>
        <Image
          className="blog-entry-image"
          src={animalCrossingFetchQuestImage}
          caption="In Animal Crossing, you share a village with your animal neighbors. The villagers will often request the player to perform fetch quests; often as favors or chores."
        />
      </Container>

      <Container>
        <BlogEntryParagraphBox>
          Fetch quests usually have a reward once completed. The great thing about fetch quests is that they can provide the player with a means of earning gold, experience, or resources. Another great aspect of fetch quests is that they can dynamically add content to a game's world with low effort. Each fetch quest text can be boiled down to:
          <span style={{ display: 'inline-block', width: '100%', fontSize: '16px', textAlign: 'center' }}>
            {/* eslint-disable-next-line no-template-curly-in-string */}
            Go to <span style={{ fontStyle: 'italic' }}>{'${PLACE_NAME_A}'}</span>, get <span style={{ fontStyle: 'italic' }}>{'${QUANTITY} ${QUANTITY_UNITS}'}</span>, and return to <span style={{ fontStyle: 'italic' }}>{'${PLACE_NAME_B}'}</span>.
          </span>
          {/* eslint-disable-next-line no-template-curly-in-string */}
          The sentence has tokens that are easy to identify and substitute as needed. This pattern can be very handy but it still has some caveats. What if I asked you to get "<span style={{ fontStyle: 'italic' }}>1 cherry</span>" versus "<span style={{ fontStyle: 'italic' }}>14 cherries</span>"? The <span style={{ fontStyle: 'italic' }}>{'${QUANTITY_UNITS}'}</span> here would be "cherry" in the first case and "cherries" in the second. Non-English languages will have similar situations. You will need to translate the associated <span style={{ fontStyle: 'italic' }}>{'${QUANTITY_UNITS}'}</span> text as needed.
        </BlogEntryParagraphBox>
      </Container>

      <Container centerText>
        <Image
          className="blog-entry-image"
          src={ocarinaPlayerNamesImage}
          caption={(
            <>
              <span>A message that is displayed during the introduction to The Legend of Zelda: Ocarina of Time. The player enters their name when they start a new game and characters will use that name. I put a red box around the different player names in the images. If the localized strings contained </span>
              {/* eslint-disable-next-line no-template-curly-in-string */}
              <span style={{ fontStyle: 'italic' }}>{'${PLAYER_NAME}'}</span>
              <span> then substitution is easy. However, allowing players to author some content could justify needing a profanity filter.</span>
            </>
          )}
        />
      </Container>

      <Container className="container">
        More info:
        <ul>
          <li>
            ISO 3166-1 country codes&nbsp;
            <WebLink
              linkText="Link"
              url="https://en.wikipedia.org/wiki/ISO_3166-1"
            />
          </li>
          <li>
            ISO 639-1 language codes&nbsp;
            <WebLink
              linkText="Link"
              url="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
            />
          </li>
          <li>
            Yacht Club Games - "Japan Localization"&nbsp;
            <WebLink
              linkText="Link"
              url="https://yachtclubgames.com/2016/07/japan-localization/"
            />
          </li>
          <li>
            Michelle Deco - "The Basics of Localization"&nbsp;
            <WebLink
              linkText="Part 1"
              url="https://www.gamasutra.com/blogs/MichelleDeco/20160908/280799/The_Basics_of_Localization.php"
            />
            &nbsp;
            <WebLink
              linkText="Part 2"
              url="https://www.gamasutra.com/blogs/MichelleDeco/20170213/291331/The_Basics_of_Localization_Part_2.php"
            />
            &nbsp;
            <WebLink
              linkText="Part 3"
              url="https://www.gamasutra.com/blogs/MichelleDeco/20170327/294528/The_Basics_of_Localization_Part_3.php"
            />
          </li>
          {/* <li>
            The Daily Dot - "Anti-abortion Twitter bot goes silent after NSFW trolling attack"&nbsp;
            <WebLink
              linkText="Link"
              url="https://www.dailydot.com/unclick/4chan-trolls-anti-abortion-bot-for-months/"
            />
          </li> */}
        </ul>
      </Container>
    </div>
  );
};
