import { FunctionComponent } from 'react';

type PropsType = {
  className?: string;
  fillColor?: string;
  pathColor?: string;
  size?: number;
  width?: number;
  height?: number;
};

// Icon from: https://publicicons.org/right-arrow-icon
export const IconArrowRight: FunctionComponent<PropsType> = ({
  className,
  fillColor = 'none',
  pathColor = '#020202',
  size,
  width,
  height,
}) => {
  const widthToUse = width ?? size ?? 200;
  const heightToUse = height ?? size ?? 200;

  return (
    <svg
      className={className}
      fill={fillColor}
      width={widthToUse}
      height={heightToUse}
      x="0px"
      y="0px"
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <rect x="2.95" y="1.921" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 7.6689 8.4842)" width="5.283" height="1.466"/>
      <rect x="0.024" y="3.157" width="6.375" height="1.683"/>
      <rect x="2.956" y="4.615" transform="matrix(-0.7069 0.7073 -0.7073 -0.7069 13.3369 5.1684)" width="5.284" height="1.465"/>
    </svg>
  );
};
