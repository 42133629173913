import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Video } from '../../pieces/video';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';
import contactSearchAddVideo from './contact-search-add.mp4';
import contactSearchAutomatedVideo from './contact-saved-search-automated-640.mp4';
import extensionImage from './browser-extension-add-contact.png';
import reportingImage from './keap-reporting.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Started as a Software Engineer II and worked up to Software Engineer III.',
  'Used Vue, HTML, JavaScript, CSS, and Java.',
  'Operated primarily in the frontend but performed backend and service layer work as needed.',
  'Added new functionality and fixed bugs in our Keap and Infusionsoft products.',
  'Updated CircleCI configurations to capture screenshots of failed tests for later examination.',
  'Setup a webhook with Slack and automated test failure notifications to all of the product teams.',
  'Wrote automated and unit tests using Nightwatch, Mocha, TestCafe, and Mockito.',
  'Updated our design system so the front-end could more closely match mockups.',
  'Committed updates, reviewed pull-requests, and performed deployments within GitHub.',
  'Assisted with company rebranding efforts.',
];

export const WorksKeap: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Keap | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Infusion Software, Inc., d/b/a Keap"
          position="Software Engineer III"
          location="Chandler, AZ"
          details={[
            'August 2018 – August 2019',
            <WebLink
              linkText="Keap"
              url="https://keap.com/"
            />
          ]}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          src={contactSearchAddVideo}
          header="When you search for a contact we would give you a link to that contact's details if they exist. Otherwise, we would just display a &quot;Not found&quot; message. I changed this to offer the ability to add the the contact if they were not found."
        />
      </Container>

      <Container centerText>
        <Video
          src={contactSearchAutomatedVideo}
          header="I enjoy writing automated tests. As a member of our internal Quality Guild, I also wrote end-to-end tests for our Infusionsoft product to help detect bugs introduced by future changes."
        />
      </Container>

      <Container centerText>
        <Image
          src={extensionImage}
          alt="Browser Extension for Hackathon"
          header="Keap holds a hackathon every few months. For our first hackathon of 2019 I created a browser extension for Chrome and Firefox that allows the user to manage their contacts without opening Keap in a full browser tab. This is useful if you want to add a contact or take down some notes during a video call. Our project manager loved this idea and it's on its way to becoming a fully supported project."
          caption="Browser extension"
        />
      </Container>

      <Container>
        <Image
          src={reportingImage}
          alt="Reports"
          header="I had implemented some basic reports before leaving Keap but it looks like they are continuing to expand on that initial work and direction."
          caption={
            <WebLink
              linkText="Keap Reporting"
              url="https://keap.com/features/reporting"
            />
          }
        />
      </Container>
    </div>
  );
};
