import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import mainBgImage from './game-main-bg-web.png';
import mainmenuImage from './mainmenu-background-en-web.png';
import mugsScreenImage from './IMG-0307-web.png';
import postcardsNorthAmericaImage from './North-America-Postcards-Collection-web.png';
import barsGameplayImage from './IMG-0302-web.png';
import tapperTrailerVideo from './Tapper-World-Tour-Trailer.mp4';
import tapperTrailerVideoPoster from './Tapper-World-Tour-Trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const features = [
  'Art by Don Bluth, famous for Dragon\'s Layer and animated films.',
  'Classic Tapper that has been refreshed with new mechanics, visuals, and fresh gameplay experience.',
  'Mini-games, leaderboards, and collectibles.',
];

const contributions = [
  'Created a content discovery system to facilitate planned content updates using the Cocos2D engine.',
  'Developed the majority of the UI. This includes the Mugs Collection, Postcards Collection, Map, Location, and Bar Select screens.',
  'Created the Shells Swap and Bull Riding mini-games.',
  'During the prototype phase, I developed a custom particle emitter system that brought each location alive with animations. **This system was replaced with fullscreen animated sprite sheets after I left the project.',
];

export const WorksTapper: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Tapper World Tour | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Tapper World Tour"
          details={[
            'Developer: Square One Studios',
            'Publisher: Warner Bros. Interactive Entertainment',
            'Released: 2011',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="http://www.tapperworldtour.com/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Features"
          list={features}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=Jqbr0u_7PlY
          src={tapperTrailerVideo}
          caption="Tapper World Tour trailer"
          poster={tapperTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={mainBgImage}
          header="The first iteration of the main menu featured a bartender that more closely resembled the classic bartender."
          caption="Old main menu design"
        />
      </Container>

      <Container centerText>
        <Image
          src={mainmenuImage}
          header="Later iterations featured two bartenders that could be used in-game."
          caption="Main menu"
        />
      </Container>

      <Container centerText>
        <Image
          src={mugsScreenImage}
          header="The Mugs Collection screen was a fun screen to implement and had some interesting challenges. It had to work with the content discovery system I created so that new mug spaces for DLC would appear automatically. The mug silhouetttes are creative and are a great motivator to complete levels."
          caption="The Mugs Collection screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={postcardsNorthAmericaImage}
          caption="Postcards unlocked for the North American locations."
        />
      </Container>

      <Container centerText>
        <Image
          src={barsGameplayImage}
          header="The characters and locations were styled in such a way to allow us to easily re-skin them for different themes. The biggest difficulty to overcome was in establishing anchor/pivot points for the characters and their different animations."
          caption="Gameplay"
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            IGN - "Tapper World Tour Review" - March 17, 2011&nbsp;
            <WebLink
              linkText="link"
              url="https://www.ign.com/articles/2011/03/17/tapper-world-tour-review"
            />
          </li>

          <li>
            Gamezebo - "Tapper World Tour Review" - March 22, 2011&nbsp;
            <WebLink
              linkText="link"
              url="https://www.gamezebo.com/reviews/tapper-world-tour-review/"
            />
          </li>

          <li>
            TouchArcade - "‘Tapper World Tour’ Review – An Updated Classic That Looks and Plays Better Than Ever" - March 17, 2011&nbsp;
            <WebLink
              linkText="link"
              url="https://toucharcade.com/2011/03/17/tapper-world-tour-review/"
            />
          </li>

          <li>
            METACRITIC - "Tapper World Tour" - March 16, 2011&nbsp;
            <WebLink
              linkText="link"
              url="https://www.metacritic.com/game/ios/tapper-world-tour"
            />
          </li>

          <li>
            Pocket Gamer - "Tapper World Tour" - March 16, 2011&nbsp;
            <WebLink
              linkText="link"
              url="https://www.pocketgamer.com/tapper-world-tour/tapper-world-tour/"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
