import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';

import headerIcon from './icon.png';
import mainMenuImage from './main-menu-smaller.png';
import raceResultsArabicImage from './hyundai-race-results-arabic-web.png';
import vehicleColorsImage from './vehicle-colors-web.png';

const features = [
  'Multiple race tracks for racing two different Hyundai vehicles.',
  'Product images, videos, and 3D panoramas that advertise Hyundai\'s new Veloster vehicle as well as their new "NEW THINKING. NEW POSSIBILITIES." slogan.',
  'Online leaderboards and customizable controls.',
];

const contributions = [
  'I updated our engine\'s text renderer to support right-to-left languages. This allowed us to display Arabic for the first time.',
  'The game re-skinned a lot of screens I had implemented in previous games. I only had to perform minor tweaks for the new UI flow.',
];

export const WorksHyundaiVeloster: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Hyundai Veloster HD | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Hyundai Veloster HD"
          details={[
            'Developer: 2XL Games, Inc. for the Hyundai Motor Company',
            'Released: 2009',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.appleAppStore}
                url="https://web.archive.org/web/20111126122024/http://itunes.apple.com/us/app/hyundai-veloster-hd/id443884314"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Features"
          list={features}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Image
          src={mainMenuImage}
          header="This project was a great opportunity to play a new car that was being released."
          caption="Main Menu"
        />
      </Container>

      <Container centerText>
        <Image
          src={raceResultsArabicImage}
          header="This is a screenshot taken while debugging the text render to support Arabic. Implementing Arabic was a great challenge. Arabic is read from right-to-left so I started by updating our text renderer to be able to render text glyphs from the right side of a text box. This quickly lead to another problem. Arabic is read from right-to-left but if an Arabic sentence contains numbers or English words then that text would need to be rendered from left-to-right. This was addressed by measuring the inner English text width, offset the rendering position by that width, rendering the English left-to-right, switch back to rendering right-to-left, offset the rendering cursor again so that it will begin to the left of the English, and continue rendering as usual. This method proved sufficient for our needs."
        />
      </Container>

      <Container centerText>
        <Image
          src={vehicleColorsImage}
          caption="Vehicle color select"
        />
      </Container>
    </div>
  );
};
