import { FunctionComponent } from 'react';
import classNames from 'classnames';

type PropsType = {
  className?: string;
  onClick: () => void;
};

export const RssCloseButton: FunctionComponent<PropsType> = ({
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(className, 'close-button-wrapper', 'is-clickable')}
      onClick={onClick}
    >
      <div className="close-button">
        <span>x</span>
      </div>
    </div>
  );
}
