import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';

import spelltornImage from '../spelltorn/dungeon-editor-and-ingame-web.png';
import streetImage from '../street/racing-mini-2.jpg';
import capitalCityImage from '../capital-city/bus-stop-web.png';
import mcgrathsOffroadImage from '../mcgraths-offroad/mcgraths-offroad-car-0-smaller.png';
import mxOffroadImage from '../mx-offroad/main-menu-0-web.png';
import mxMatchupImage from '../mx-matchup/match-found-web.png';
import hyundaiTimeSquareImage from '../hyundai-time-square/connection-scan-web.png';
import trophyLiteImage from '../trophylite/race-3-web.png';
import snocrossImage from '../snocross/main-menu-web.png';
import hyundaiVelosterImage from '../hyundai-veloster/vehicle-colors-web.png';
import fleetDefenseImage from '../fleet-defense/main-menu-0-web.png';
import atvOffroadImage from '../atv-offroad/billboard-riders-0-web.png';
import { WorksUrlTokens } from '../constants';
import { LocalLink, WebLink } from '../../pieces/web-link';

type ProjectEntry = {
  title: string;
  year: number;
  link: string;
  imageSrc: string;
  description?: React.ReactNode;
};

const projects: ProjectEntry[] = [{
  title: 'Spelltorn - Clash of Fates',
  year: 2014,
  link: `../${WorksUrlTokens.spelltorn}`,
  imageSrc: spelltornImage,
  description: (
    <>
      <div>This game allowed for an asynchronous, player-vs-player battling as well as single player, dungeon-crawling, adventure. The dungeons had a lot of interesting characters, branching story lines, and creative monsters to fight.</div>
      <div className="game-description">Game Description:</div>
      <div>Spelltorn is a new take on the classic RPG. It is the only Massively Multiplayer Online game with a branching storyline where the choices you make affect your journey! See different dungeons, fight different monsters and reach different endings! Spelltorn was written by John and Laura Lakey, the original creative team behind the Dungeons and Dragons Guidebooks.</div>
    </>
  ),
}, {
  title: '2XL Racing',
  year: 2014,
  link: `../${WorksUrlTokens.street}`,
  imageSrc: streetImage,
  description: (
    <>
      <div>2XL Racing pitted players against eachother in a realtime competition of reaction speed. The vehicle models were all crafted in-house and came out beautifully.</div>
      <div className="game-description">Game Description:</div>
      <div>2XL Racing leaves the competition in the dust! Featuring dozens of licensed cars, multiplayer racing and Street Wars, a battle for the city streets of Los Angeles.</div>
    </>
  ),
}, {
  title: 'Capital City',
  year: 2014,
  link: `../${WorksUrlTokens.capitalCity}`,
  imageSrc: capitalCityImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>It’s time to channel your inner-MOGUL and conquer Capital City!</div>
      <br />
      <div>Invite up to five friends, choose from one of 11 over-the-top vehicles, hone your strategy, and get ready to make some fast deals! Everything - every coffee shop and car wash; every fourplex and fixer-upper – has the potential for opportunity! But just like in real life, not every deal is a good one. The key is to acquire the RIGHT houses, apartments and businesses before your opponents do.</div>
    </>
  ),
}, {
  title: 'Jeremy McGrath\'s Offroad',
  year: 2012,
  link: `../${WorksUrlTokens.mcgrathsOffroad}`,
  imageSrc: mcgrathsOffroadImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>In Jeremy McGrath’s Offroad, players race four-wheeled, 800 horsepower off road race trucks with tons of options, including a host of breathtaking race tracks, single-player and multiplayer modes as well as a wide loadout of vehicle classes including Rally Cars, Pro Buggies, and Trophylite Trucks, all in crispy 1080p, 60 frames per second presentation.</div>
      <br />
      <div>With multiplayer support for eight racers and a full career mode, Jeremy McGrath’s Offroad will be the title for racing fans who want to get down and dirty on the track!</div>
    </>
  ),
}, {
  title: '2XL MX Offroad',
  year: 2012,
  link: `../${WorksUrlTokens.mxOffroad}`,
  imageSrc: mxOffroadImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>2XL MX Offroad combines two of our hit iOS games, Supercross and ATV Offroad! Race ATV and dirt bikes head to head.</div>
    </>
  ),
}, {
  title: 'Ricky Carmichael\'s Motocross Matchup',
  year: 2011,
  link: `../${WorksUrlTokens.mxMatchup}`,
  imageSrc: mxMatchupImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>Ricky Carmichael's Motocross Matchup Pro is an all-new racing game where it's just you against online competitors from around the globe! This includes the chance to bang bars with some of the best in the business, including Ricky Carmichael himself!</div>
      <br />
      <div>Play Matchup mode and go head-to-head on both indoor (based on the real 2011 Supercross track layouts) and outdoor nationals tracks, including Ricky's very own backyard.</div>
    </>
  ),
}, {
  title: 'Hyundai Veloster Time Square',
  year: 2011,
  link: `../${WorksUrlTokens.hyundaiTimeSquare}`,
  imageSrc: hyundaiTimeSquareImage,
}, {
  title: '2XL TROPHYLITE Rally',
  year: 2010,
  link: `../${WorksUrlTokens.trophylite}`,
  imageSrc: trophyLiteImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>Once again raising the graphics quality you should expect on iOS. Learn how to power‑slide around corners and through hairpin turns with an original physics simulation you have yet to experience!</div>
    </>
  ),
}, {
  title: 'X Games SnoCross',
  year: 2010,
  link: `../${WorksUrlTokens.snocross}`,
  imageSrc: snocrossImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>Catch some big air, show off your best tricks, and wear your medals proudly. Now looking great in HD on iPad and iPhone 5 Retina displays!</div>
      <br />
      <div>Whether you’re an amateur or on par with medalists Levi LaVallee and Heath Frisby, ride and compete on courses based on the Snocross and freestyle competitions.</div>
    </>
  ),
}, {
  title: 'Hyundai Veloster HD',
  year: 2009,
  link: `../${WorksUrlTokens.hyundaiVeloster}`,
  imageSrc: hyundaiVelosterImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>The Veloster HD App is a fun filled racing game that features the all-new 2012 Veloster. In celebration of the Veloster global debut, we wanted to create an app to capture its innovative style and design, and also allow customers to test drive and interact from anywhere in the world. Whether you are a customer, a fan, or a curious gamer, we hope you download and enjoy.</div>
    </>
  ),
}, {
  title: '2XL Fleet Defense',
  year: 2009,
  link: `../${WorksUrlTokens.fleetDefense}`,
  imageSrc: fleetDefenseImage,
  description: (
    <>
      <div className="game-description">Game Description:</div>
      <div>Caught in the middle of an international crisis, you are the naval aviator charged with the defense of your carrier and the lives that depend on it from the enemy’s relentless attack. Fortunately, you are in control of the world’s most advanced jet fighter. This advanced fighter is a modern marvel, centralizing all information and controls at your finger tips.</div>
    </>
  ),
}, {
  title: '2XL ATV Offroad',
  year: 2009,
  link: `../${WorksUrlTokens.atvOffroad}`,
  imageSrc: atvOffroadImage,
  description: (
    <>
      <div>ATV racing, jump tricks, and backflips.</div>
      <div className="game-description">Game Description:</div>
      <div>From the makers of “2XL Supercross” comes the hottest, action-packed racing game to hit the iPhone and iPod Touch… “2XL ATV Offroad”</div>
      <br />
      <div>Interested in extreme sports? Catch some sick air and combo as many tricks as you can for big points with our innovative new stunt system. Learn to back flip while tricking for extra point multipliers.</div>
    </>
  ),
}];

const toolsUtils = [
  'Visual Studio',
  'XCode',
  'C/C++',
  'C#',
  'Objective-C',
  'Java',
  '3D Studio Max',
  'Xbox 360 / PlayStation 3 Development Kit',
  'A variety of iPhone, iPad, and iPod Touch devices for testing'
];

export const Works2XLGames: FunctionComponent = () => {
  useEffect(() => {
    document.title = "2XL Games | Joe Gosselin";
 }, []);

  return (
    <div className="two-xl-games-work">
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL Games, Inc."
          position="Developer"
          location="Phoenix, AZ"
          details={[
            'July 2009 - June 2014',
            <WebLink
              linkText="2XL Games"
              url="https://web.archive.org/web/20190204035926/http://2xlgames.com/"
            />
          ]}
        />
      </Container>

      <Container>
        <WorkList
          header="Languages / Tools"
          list={toolsUtils}
          columnCount={2}
        />
      </Container>

      {projects.map((projectEntry, index) => {
        return (
          <Container
            centerText
            key={index}
          >
            <Image
              src={projectEntry.imageSrc}
              alt={projectEntry.title}
              caption={(
                <>
                  <div className="project-title">{`${projectEntry.title} (${projectEntry.year})`}</div>

                  {projectEntry.description && (
                    <div className="project-description">{projectEntry.description}</div>
                  )}

                  {projectEntry.link && (
                    <>
                      <br />
                      <LocalLink
                        linkText="Go to Project"
                        linkTo={projectEntry.link}
                      />
                    </>
                  )}
                </>
              )}
            />
          </Container>
        );
      })}
    </div>
  );
};
