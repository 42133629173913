import { FunctionComponent, useEffect } from 'react';
import { HomepageTile, HomepageTilePropsType } from '../homepage-tile';

import gpsInsightIcon from '../works/gps-insight/icon.png';
import gpsInsightIconAlt from '../works/gps-insight/icon-alt.png';

import keapIcon from '../works/keap/icon.png';
import keapIconAlt from '../works/keap/icon-alt.png';

import baselayerIcon from '../works/baselayer/icon.jpeg';
import baselayerIconAlt from '../works/baselayer/icon-alt.jpg';

import generalMotorsIcon from '../works/general-motors/icon.png';
import generalMotorsIconAlt from '../works/general-motors/icon-alt.png';

import twoxlGamesIcon from '../works/2xl-games/icon.png';
import twoxlGamesIconAlt from '../works/2xl-games/icon-alt.png';

import spelltornIcon from '../works/spelltorn/icon.png';
import spelltornIconAlt from '../works/spelltorn/icon-alt.png';

import xlStreetIcon from '../works/street/icon.jpg';
import xlStreetIconAlt from '../works/street/icon-alt.jpg';

import capitalCityIcon from '../works/capital-city/icon.png';
import capitalCityIconAlt from '../works/capital-city/icon-alt.png';

import mcgrathIcon from '../works/mcgraths-offroad/icon.png';
import mcgrathIconAlt from '../works/mcgraths-offroad/icon-alt.png';

import mxOffroadIcon from '../works/mx-offroad/icon.png';
import mxOffroadIconAlt from '../works/mx-offroad/icon-alt.png';

import mxMatchupIcon from '../works/mx-matchup/icon.png';
import mxMatchupIconAlt from '../works/mx-matchup/icon-alt.png';

import tapperIcon from '../works/tapper/icon.png';
import tapperIconAlt from '../works/tapper/icon-alt.png';

import hyundaiTimeSquareIcon from '../works/hyundai-time-square/icon.png';

import trophyLiteIcon from '../works/trophylite/icon.png';
import trophyLiteIconAlt from '../works/trophylite/icon-alt.png';

import snocrossIcon from '../works/snocross/icon.png';
import snocrossIconAlt from '../works/snocross/icon-alt.png';

import velosterIcon from '../works/hyundai-veloster/icon.png';
import velosterIconAlt from '../works/hyundai-veloster/icon-alt.png';

import fleetDefenseIcon from '../works/fleet-defense/icon.png';
import fleetDefenseIconAlt from '../works/fleet-defense/icon-alt.png';

import atvOffroadIcon from '../works/atv-offroad/icon.png';
import atvOffroadIconAlt from '../works/atv-offroad/icon-alt.png';

import wavesOfDestructionIcon from '../works/waves-of-destruction/icon.png';
import wavesOfDestructionIconAlt from '../works/waves-of-destruction/icon-alt.png';

import squareDestroyerIcon from '../works/square-destroyer/icon.png';
import squareDestroyerIconAlt from '../works/square-destroyer/icon-alt.png';

import talonIcon from '../works/talon/icon.png';
import talonIconAlt from '../works/talon/icon-alt.png';

import rssIcon from '../works/rss/icon.png';
import { WorksUrlTokens } from '../works/constants';
import { RSS_URL_TOKEN } from '../works/rss/constants';

type HomepagePanelProps = HomepageTilePropsType & {
  isHidden?: boolean;
};

const workPanelsData: HomepagePanelProps[] = [{
  // GPS Insight (2019 - Present).
  text: (
    <>
      <div>GPS Insight</div>
      <div>(2019 - Present)</div>
    </>
  ),
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.gpsi}`,
  imagePath: gpsInsightIcon,
  mouseoverImagePath: gpsInsightIconAlt,
}, {
  // Keap, LLC (2018 - 2019).
  text: (
    <>
      <div>Keap | Infusionsoft</div>
      <div>(2018 - 2019)</div>
    </>
  ),
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.keap}`,
  imagePath: keapIcon,
  mouseoverImagePath: keapIconAlt,
}, {
  // Baselayer Technology, LLC (2015 - 2018).
  text: (
    <>
      <div>BASELAYER | RunSmart</div>
      <div>(2015 - 2018)</div>
    </>
  ),
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.baselayer}`,
  imagePath: baselayerIcon,
  mouseoverImagePath: baselayerIconAlt,
}, {
  // General Motors (2015).
  text: (
    <>
      <div>General Motors</div>
      <div>(2014 - 2015)</div>
    </>
  ),
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.generalMotors}`,
  imagePath: generalMotorsIcon,
  mouseoverImagePath: generalMotorsIconAlt,
}, {
  // 2XL Games (2009 - 2014).
  text: (
    <>
      <div>2XL Games, Inc.</div>
      <div>(2009 - 2014)</div>
    </>
  ),
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.twoxlGames}`,
  imagePath: twoxlGamesIcon,
  mouseoverImagePath: twoxlGamesIconAlt,
}];

const projectPanelsData: HomepagePanelProps[] = [{
  // Spelltorn - Clash of Fates (2014).
  text: 'Spelltorn - Clash of Fates',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.spelltorn}`,
  imagePath: spelltornIcon,
  mouseoverImagePath: spelltornIconAlt,
}, {
  // 2XL Racing (2014).
  text: '2XL Racing',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.street}`,
  imagePath: xlStreetIcon,
  mouseoverImagePath: xlStreetIconAlt,
}, {
  // Capital City (2014).
  text: 'Capital City',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.capitalCity}`,
  imagePath: capitalCityIcon,
  mouseoverImagePath: capitalCityIconAlt,
}, {
  // Jeremy McGrath's Offroad (2012).
  text: 'Jeremy McGrath\'s Offroad',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.mcgrathsOffroad}`,
  imagePath: mcgrathIcon,
  mouseoverImagePath: mcgrathIconAlt,
}, {
  // 2XL MX Offroad (2012).
  text: 'MX Offroad',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.mxOffroad}`,
  imagePath: mxOffroadIcon,
  mouseoverImagePath: mxOffroadIconAlt,
}, {
  // Ricky Carmichael's Motocross Matchup (2011).
  text: 'RC\'s Motocross Matchup',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.mxMatchup}`,
  imagePath: mxMatchupIcon,
  mouseoverImagePath: mxMatchupIconAlt,
}, {
  // Tapper (2011).
  text: 'Tapper World Tour',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.tapper}`,
  imagePath: tapperIcon,
  mouseoverImagePath: tapperIconAlt,
}, {
  // Hyundai Veloster Time Square (2011).
  text: 'Hyundai Veloster at Time Square',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.hyundaiTimeSquare}`,
  imagePath: hyundaiTimeSquareIcon,
  mouseoverImagePath: null,
}, {
  // 2XL TROPHYLITE Rally (2010).
  text: 'TrophyLite HD',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.trophylite}`,
  imagePath: trophyLiteIcon,
  mouseoverImagePath: trophyLiteIconAlt,
}, {
  // 2XL Snocross (2010).
  text: 'X Games Snocross',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.snocross}`,
  imagePath: snocrossIcon,
  mouseoverImagePath: snocrossIconAlt,
}, {
  // Hyundai Veloster HD (2009).
  text: 'Hyundai Veloster HD',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.hyundaiVeloster}`,
  imagePath: velosterIcon,
  mouseoverImagePath: velosterIconAlt,
}, {
  // 2XL Fleet Defense (2009).
  text: 'Fleet Defense',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.fleetDefense}`,
  imagePath: fleetDefenseIcon,
  mouseoverImagePath: fleetDefenseIconAlt,
}, {
  // 2XL ATV Offroad (2009).
  text: 'ATV Offroad',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.atvOffroad}`,
  imagePath: atvOffroadIcon,
  mouseoverImagePath: atvOffroadIconAlt,
}, {
  // Waves of Destruction (2005).
  text: 'Waves of Destruction',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.wavesOfDestruction}`,
  imagePath: wavesOfDestructionIcon,
  mouseoverImagePath: wavesOfDestructionIconAlt,
}, {
  // Square Destroyer (2005).
  text: 'Square Destroyer',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.squareDestroyer}`,
  imagePath: squareDestroyerIcon,
  mouseoverImagePath: squareDestroyerIconAlt,
}, {
  // Talon (2005).
  text: 'Talon',
  linkUrl: `${WorksUrlTokens.ROOT}/${WorksUrlTokens.talon}`,
  imagePath: talonIcon,
  mouseoverImagePath: talonIconAlt,
}, {
  // RSS feed.
  text: 'Custom RSS Feed',
  linkUrl: `/${RSS_URL_TOKEN}`,
  imagePath: rssIcon,
  mouseoverImagePath: null,
}];

export const HomepageTiles: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Joe Gosselin";
 }, []);

  return (
    <div className="homepage-tiles-container">
      <div className="homepage-tiles">
        {workPanelsData.map((panelData, index) => {
          if (panelData.isHidden) {
            return null;
          }

          return (
            <HomepageTile
              key={index}
              text={panelData.text}
              linkUrl={panelData.linkUrl}
              imagePath={panelData.imagePath}
              mouseoverImagePath={panelData.mouseoverImagePath}
            />
          );
        })}
      </div>

      <div className="homepage-divider-bar" />
      <h2 className="homepage-divider-label">Projects</h2>

      <div className="homepage-tiles">
        {projectPanelsData.map((panelData, index) => {
          if (panelData.isHidden) {
            return null;
          }

          return (
            <HomepageTile
              key={index}
              text={panelData.text}
              linkUrl={panelData.linkUrl}
              imagePath={panelData.imagePath}
              mouseoverImagePath={panelData.mouseoverImagePath}
            />
          );
        })}
      </div>
    </div>
  );
};
