import { FunctionComponent, useEffect } from 'react';
import { Container } from '../pieces/container';

const skills = [
  'React / Vue / HTML',
  'CSS / SASS / LESS',
  'C / C++',
  'C#',
  'Java',
  // 'JSP',
  'PHP',
  // 'Objective-C',
  'SQL / GraphQL / No SQL',
  'JSON',
  'XML',
  'Python',
  'Microsoft Visual Studio',
  'Visual Studio Code',
  // 'Xcode',
  // 'Eclipse',
  'git',
  'TortoiseSVN',
  // 'MySQL',
  'REST',
  'MVC',
  // 'jQuery',
  'Node.js',
  'TestCafe / Nightwatch',
  'Mocha',
  // 'Mockito',
  // 'gDEBugger',
  'Blender / 3D Studio Max',
  // 'Cocos2d for iPhone',
  'JIRA',
  // 'Strapi',
  // 'Jenkins',
  // 'Mantis Bug Tracker',
  // 'IBM Rational ClearCase',
  // 'HP Quality Center',
  // 'Microsoft SharePoint',
  'Microsoft Office',
  'Agile / SCRUM',
];

export const Resume: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Résumé | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <div className="resume-page">
          {/* <iframe
            title="Joe Gosselin - Résumé"
            src="./GosselinJoe_Resume.htm"
            style={{ width: '100%', height: '900px', border: 'none' }}
          /> */}

          <div className="title-container">
            JOE GOSSELIN  |  Software Engineer
          </div>

          <div className="contact-details-container">
            Phoenix, AZ 85040&nbsp;&nbsp;|&nbsp;&nbsp;

            <a
              href="https://www.joegosselin.com/"
              target="_blank"
              rel="noreferrer"
            >
              joegosselin.com
            </a>

            &nbsp;&nbsp;|&nbsp;&nbsp;
            
            <a href="mailto:joe@joegosselin.com">
              joe@joegosselin.com
            </a>
          </div>

          <br />

          <div className="self-description-snippet">
            Software engineer with over a decade of industry experience creating, maintaining, and enhancing web apps, mobile apps, and test automations. Self-motivated team player with high attention to detail.
          </div>

          <br />

          <div className="section">
            <div className="section-content">
              <div>
                <span className="bold-label">
                  Skills:&nbsp;
                </span>
                
                <ul className="list-box col-count-dynamic">
                  {skills.map((entry, index) => {
                    return (
                      <li key={index}>
                        {entry}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div>
                <span className="bold-label">
                  Platforms:&nbsp;
                </span>

                <span>
                  Windows, Mac, Ubuntu/Linux, Android, iOS, PlayStation 3, Xbox 360.
                </span>
              </div>
            </div>
          </div>

          <br />

          <div className="section">
            <div className="section-header-label">
              EXPERIENCE
            </div>

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Senior Developer
                </span>

                <span>
                  &nbsp;&nbsp;|  GPS Insight, Inc.  |  Scottsdale, AZ  |  August 2019 - Present
                </span>
              </div>

              <ul>
                <li>
                  Worked on a new web app using React and PHP to replace an older app.
                </li>
                <li>
                  Conducted thorough code reviews and provided feedback on change requests.
                </li>
                <li>
                  Implemented Drift in the frontend for live customer service chat and automated playbooks.
                </li>
                <li>
                  Refactored Map code for major performance gains. Potentially saved our largest customer.
                </li>
                <li>
                  Collaborated with the hardware installation team to create a hardware installation verification web app.
                </li>
                <li>
                  Worked with a Director of Engineering to update an application used to generate data for demonstrations.
                </li>
                <li>
                  Assisted Support personnel with debugging and understanding expected application behavior.
                </li>
                <li>
                  Implemented majority of new web app report forms.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Software Engineer II
                </span>

                <span>
                  &nbsp;&nbsp;|  Infusion Software, Inc., d/b/a Keap  |  Chandler, AZ  |  August 2018 - August 2019
                </span>
              </div>

              <ul>
                <li>
                  Operated primarily in the frontend but performed backend and service layer work as needed.
                </li>
                <li>
                  Added new functionality and fixed bugs in our Keap single-page application and Infusionsoft products.
                </li>
                <li>
                  Wrote automated and unit tests using Nightwatch, Mocha, TestCafe, and Mockito.
                </li>
                <li>
                  Committed updates, reviewed pull-requests, and performed deployments within GitHub.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Lead Content Software Engineer
                </span>

                <span>
                  &nbsp;&nbsp;|  BASELAYER Technology, LLC  |  Chandler, AZ  |  August 2015 - February 2018
                </span>
              </div>

              <ul>
                <li>
                  Created an embeddable graphing utility capable of handling over 100,000 data points, that clients highly praised.
                </li>
                <li>
                  Dynamically generated floor plans and modules with programmatic heat maps and search capabilities.
                </li>
                <li>
                  Collaborated closely with key stakeholders, product, development, and design teams to contribute to versions 3.5, 3.7, and 4.0 of our enterprise infrastructure management product. 
                </li>
                <li>
                  Conducted code reviews with junior developers to identify performance and scalability improvements.
                </li>
                <li>
                  Wrote web services using Node.js, SQL queries using T-SQL and Postgres, and assisted with QA with writing Selenium tests.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Software Engineer / Web Developer
                </span>

                <span>
                  &nbsp;&nbsp;|  General Motors  |  Chandler, AZ  |  July 2014 - August 2015
                </span>
              </div>

              <ul>
                <li>
                  Developed an application for internal business customers with an annual direct business benefit of over $500,000.
                </li>
                <li>
                  Automated and simplified various processes that analyzed the global competitive landscape.
                </li>
                <li>
                  Reduced executive report generation time from three months down to only five days.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Lead UI Software Engineer
                </span>

                <span>
                  &nbsp;&nbsp;|  2XL Games, Inc.  |  Phoenix, AZ  |  July 2009 - June 2014
                </span>
              </div>

              <ul>
                <li>
                  Shipped 13 products for console, PC, and mobile platforms.
                </li>
                <li>
                  Frequently collaborated with art team to create and support custom tools that facilitated content creation.
                </li>
                <li>
                  Managed data and communications for multiple applications that functioned in a client-server environment.
                </li>
                <li>
                  Selected by management to train a partner team in the Philippines to fully utilize the 2XL codebase and methods.
                </li>
                <li>
                  Overhauled text renderer to support rendering Arabic and English grammatical constructions properly.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Software Engineer (Contract)
                </span>

                <span>
                  &nbsp;&nbsp;|  Square One Studios  |  Phoenix, AZ  |  July 2010 - March 2011
                </span>
              </div>

              <ul>
                <li>
                  Developed Tapper World Tour for iOS and published by Warner Bros. Interactive Entertainment.
                </li>
                <li>
                  Created a content discovery system to facilitate planned content updates using Cocos2d for iPhone.
                </li>
              </ul>
            </div>

            <br />

            <div className="section-content">
              <div>
                <span className="bold-label">
                Quality Assurance Engineer
                </span>

                <span>
                  &nbsp;&nbsp;|  Avnet, Inc.  |  Phoenix, AZ  |  August 2007 - June 2009
                </span>
              </div>

              <ul>
                <li>
                Wrote and executed test scripts on upgrades to an already existing product for this Fortune 500 company.
                </li>
                <li>
                Identified critical security vulnerabilities in a publicly facing interface that could have wrought immense damage.
                </li>
                <li>
                Cooperated with developers, testers, and business analysts to meet project-critical deadlines.
                </li>
              </ul>
            </div>
          </div>

          <br />

          <div className="section">
            <div className="section-header-label">
              EDUCATION
            </div>

            <div className="section-content">
              <div>
                <span className="bold-label">
                  Bachelor of Science in Software Engineering
                </span>

                <span>
                  &nbsp;&nbsp;|  University of Advancing Technology  |  Tempe, AZ  |  December 2008
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
