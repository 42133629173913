import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import connectionScanImage from './connection-scan-web.png';
import timeSquarePromoVideo from './hyundai-race-time-square-promo.mp4';
import timeSquarePromoVideoPoster from './hyundai-race-time-square-promo-poster.png';
import childPlayingVideo from './hyundai-race-time-square-child.mp4';
import childPlayingVideoPoster from './hyundai-race-time-square-child-poster.png';

export const WorksHyundaiTimeSquare: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Hyundai Veloster Time Square | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Hyundai Veloster Time Square"
          details={[
            'Developer: 2XL Games, Inc. for the Hyundai Motor Company',
            'Released: 2011',
            'Platforms: iOS',
          ]}
        />
      </Container>

      {/* Video no longer available. */}
      {/* <Container centerText>
        <iframe
          title="Game trailer"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/7blEm28SVQo?start=31&rel=0"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          // allowfullscreen
        />
        <FigCaption
          caption="I am thankful for this opportunity and it was greatly satisfying to see people enjoy something my team worked on. (Source: YouTube user HyundaiUSA)"
        />
      </Container> */}

      <Container centerText>
        <Image
          src={connectionScanImage}
          header="This project was interesting because it would use a long range wifi connection in Time Square in New York City to match two people for racing and then they would see their race broadcast to a large screen."
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=Z1oDSXEPZCg
          src={timeSquarePromoVideo}
          header="Interactive art at Time Square - The game can be seen in the beginning of this video."
          caption="(Source: YouTube user Hyundai Chile)"
          poster={timeSquarePromoVideoPoster}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=tSlHYAHiiwM
          src={childPlayingVideo}
          header="Hyundai Race Time Square"
          caption="(Source: YouTube user Yi Jeseok)"
          poster={childPlayingVideoPoster}
        />
      </Container>
    </div>
  );
};
