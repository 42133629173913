import { FunctionComponent } from 'react';

type PropsType = {
  iconPath?: string;
  header: string;
};

export const PageHeaderImage: FunctionComponent<PropsType> = ({
  iconPath,
  header,
}) => {
  return (
    <img
      className="page-header-image icon"
      src={iconPath}
      alt={header}
    />
  );
};
