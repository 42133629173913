import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';

import { BlogList } from './blog-list';
import { Localization } from './2014-08-29-localization/index';
import { ArabicTextRendering } from './2018-02-27-arabic/index';
import { JpegDecoder } from './2018-03-27-jpeg-decoder/index';

export const Blog: FunctionComponent = () => {
  return (
    <div className="blog-container">
      <Routes>
        <Route
          path="/"
          element={<BlogList />}
        />

        <Route
          path="/2018-03-27-jpeg-decoder"
          element={<JpegDecoder />}
        />

        <Route
          path="/2018-02-27-arabic"
          element={<ArabicTextRendering />}
        />

        <Route
          path="/2014-08-29-localization"
          element={<Localization />}
        />
      </Routes>
    </div>
  );
};
