import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { IconExternal } from '../icon-external';
import { IconArrowRight } from '../icon-arrow-right';

type SharedPropsType = {
  linkText: string;
};

type LocalLinkPropsType = SharedPropsType & {
  linkTo: string;
  openInNewPage?: boolean;
};

export const LocalLink: FunctionComponent<LocalLinkPropsType> = ({
  linkText,
  linkTo,
}) => {
  return (
    <div className="local-link">
      <Link
        className="link-tag"
        to={linkTo}
      >
        [
          {linkText}
          &nbsp;
          <IconArrowRight
            className="link-icon"
            fillColor='#FFF'
            size={14}
          />
        ]
      </Link>
    </div>
  );
};


type WebLinkPropsType = SharedPropsType & {
  url: string;
  openInNewPage?: boolean;
};

export const WebLink: FunctionComponent<WebLinkPropsType> = ({
  linkText,
  url,
  openInNewPage = true,
}) => {
  return (
    <div className="web-link">
      <a
        className="link-tag"
        href={url}
        target={openInNewPage ? "_blank" : "_self"}
        rel="noreferrer"
      >
        [
          {linkText}
          &nbsp;
          <IconExternal
            className="link-icon"
            pathColor='#FFF'
            size={14}
          />
        ]
      </a>
    </div>
  );
};
