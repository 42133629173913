import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import titleScreenImage from './main-menu-0-web.png';
import racingImage from './race-0-web.png';
import mxOffroadGameplayVideo from './2XL-MX-Offroad-Gameplay-footage-for-Android-Phones.mp4';
import mxOffroadGameplayVideoPoster from './2XL-MX-Offroad-Gameplay-footage-for-Android-Phones-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Updated game to use freemium monetization model.',
  'Implemented the Tapjoy SDK. The user earns points for seeing advertisements. The points are then used to unlock game features.',
  'Implemented the OpenFeint SDK for tracking the user\'s achievements.',
];

export const WorksMxOffroad: FunctionComponent = () => {
  useEffect(() => {
    document.title = "2XL MX Offroad | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL MX Offroad"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2012',
            'Platforms: iOS and Android',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.googlePlay}
                url="https://web.archive.org/web/20211022013140/https://play.google.com/store/apps/details?id=com.twoxlgames.mxoffroad"
              />

              <AppLink
                appType={AppType.appleAppStore}
                url="https://itunes.apple.com/us/app/2xl-mx-offroad/id487272100?mt=8"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/20180915010507/http://2xlgames.com/mxo/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Image
          src={titleScreenImage}
          caption="Title screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={racingImage}
          caption="Gameplay"
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=Uz2rc-9ClHc
          src={mxOffroadGameplayVideo}
          caption="Gameplay footage for Android (Source: YouTube user &quot;good android apps&quot;)"
          poster={mxOffroadGameplayVideoPoster}
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            Justuseapp - "2XL MX Offroad Reviews"&nbsp;
            <WebLink
              linkText="link"
              url="https://justuseapp.com/en/app/487272100/2xl-mx-offroad/reviews"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
