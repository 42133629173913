import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

type PropsType = {
  children?: React.ReactNode;
};

export const ScrollToTopOfElement: FunctionComponent<PropsType> = ({ children }) => {
  const { pathname } = useLocation();

  const childContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childContainerRef && childContainerRef.current) {
      childContainerRef.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div
      className="scroll-to-top"
      ref={childContainerRef}
    >
      {children}
    </div>
  );
};
