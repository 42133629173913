import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import worldMapImage from './world-map-web.png';
import dungeonEditorAndInGameWebImage from './dungeon-editor-and-ingame-web.png';
import fightListImage from './fight-list-0-web.png';
import scriptedDialogImage from './scripted-dialog.png';
import pnpWebImage from './pvp-web.png';
import spelltornGameplayTrailerVideo from './spelltorn-clash-of-fates-gameplay-trailer.mp4';
import spelltornGameplayTrailerVideoPoster from './spelltorn-clash-of-fates-gameplay-trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const features = [
  'Branching story structure written by John and Laura Lakey of Dungeons & Dragons fame. Easily extensible for future content.',
  'Turn-based combat against A.I. enemies.',
  'Wide selection of weapons, armor, and character customizations.',
  'Character class specialization options and weapon crafting.',
  'One-on-one alliances and guild creation/membership.',
  'Context-aware offer system.',
  'Incremental rewards for daily players.',
  'Special events with prizes for event leaders.',
  'Global and Battle chat channels running in real-time. Actively filtered for vulgarity.',
  'Public messages and private message (PM) boards for each character.',
  'Social integration, leaderboards, and friend referral rewards.',
];

const contributions = [
  'Instructed and worked on-site with teammates in The Philippines.',
  'Created tool for designers to create/edit dungeons.',
  'Created simulator to play dungeons without the need for a server.',
  'Created tool for scripting tutorial battles.',
  'Wrote all code involved with user interaction within the dungeons.',
  'Created branching system for unlocking dungeons allowing for choose-your-own-adventure world progression.',
  'Worked closely with designers and artists to ensure a quality experience across a multitude of unique mobile displays.',
  'Implemented a large portion of the application\'s overall UI.',
  'Created a tool for authoring quests and the server code to support them.',
  'Optimized server communications in preparation for heavy use.',
];

export const WorksSpelltorn: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Spelltorn - Clash of Fates | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Spelltorn - Clash of Fates"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2014',
            'Platforms: iOS and Android',
            'Genre: Role-playing game (RPG)',
            'Software: Visual Studio, Photoshop, 3D Studio Max',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.googlePlay}
                url="https://web.archive.org/web/20170411040002/https://play.google.com/store/apps/details?id=com.twoxlgames.spelltorn"
              />

              <AppLink
                appType={AppType.appleAppStore}
                url="https://web.archive.org/web/20210227172356/https://apps.apple.com/us/app/spelltorn-clash-of-fates-rpg/id893793022"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="http://2xlgames.com/spelltorn/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Features"
          list={features}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=Wpngby33LQ8
          src={spelltornGameplayTrailerVideo}
          caption="Spelltorn gameplay trailer"
          poster={spelltornGameplayTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={worldMapImage}
          header="The game world connecting the individual dungeons. The user's decisions determine which dungeons are unlocked. This creates a story custom to the user and is extensible for future content."
          caption="Game world"
        />
      </Container>

      <Container centerText>
        <Image
          src={dungeonEditorAndInGameWebImage}
          header="Dungeon being authored in the Dungeon Editor (Left) and the same dungeon being played in-game (Right). The Dungeon Editor and Dungeon Simulator tools allowed for quick design iterations."
        />
      </Container>

      <Container centerText>
        <Image
          src={fightListImage}
          header="Content resizes to fit the display. This is essential to delivering a great experience for users regardless of the device they are playing on. A taller screen means more list items display at once."
          caption="Opponent search screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={scriptedDialogImage}
          header="My tool made it easy to script dialog within dungeons and reward or punish the user for their choices. One of our designers hid a joke from Arrested Development here."
          caption="Scripted dialogue"
        />
      </Container>

      <Container centerText>
        <Image
          src={pnpWebImage}
          header="To hinder cheating, I wrote the combat system to work off of button indices. For example, pressing the Heal button only tells the server that you pressed the third button. The server determines what the third button was and if it is valid before taking any action."
          caption="Battle in progress"
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            The Sydney Morning Herald - "Is this game the next World of Warcraft?" - October 18, 2013&nbsp;
            <WebLink
              linkText="link"
              url="https://www.smh.com.au/business/small-business/is-this-game-the-next-world-of-warcraft-20131018-2vrvq.html"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
