import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { WorksWavesOfDestruction } from './waves-of-destruction';
import { WorksAtvOffroad } from './atv-offroad';
import { WorksBaselayer } from './baselayer';
import { WorksCapitalCity } from './capital-city';
import { WorksFleetDefense } from './fleet-defense';
import { WorksGeneralMotors } from './general-motors';
import { WorksGpsInsight } from './gps-insight';
import { Works2XLGames } from './2xl-games';
import { WorksHyundaiTimeSquare } from './hyundai-time-square';
import { WorksHyundaiVeloster } from './hyundai-veloster';
import { WorksKeap } from './keap';
import { WorksMcGrathsOffroad } from './mcgraths-offroad';
import { WorksMxMatchup } from './mx-matchup';
import { WorksMxOffroad } from './mx-offroad';
import { WorksSnocross } from './snocross';
import { WorksSpelltorn } from './spelltorn';
import { WorksStreet } from './street';
import { WorksTapper } from './tapper';
import { WorksTrophyLite } from './trophylite';
import { WorksSquareDestroyer } from './square-destroyer';
import { WorksUrlTokens } from './constants';
import { WorksTalon } from './talon';

export const Works: FunctionComponent = () => {
  return (
    <div
      className="work-container"
    >
      <Routes>
        <Route
          path={WorksUrlTokens.gpsi}
          element={<WorksGpsInsight />}
        />

        <Route
          path={WorksUrlTokens.keap}
          element={<WorksKeap />}
        />

        <Route
          path={WorksUrlTokens.baselayer}
          element={<WorksBaselayer />}
        />

        <Route
          path={WorksUrlTokens.generalMotors}
          element={<WorksGeneralMotors />}
        />

        <Route
          path={WorksUrlTokens.twoxlGames}
          element={<Works2XLGames />}
        />

        <Route
          path={WorksUrlTokens.spelltorn}
          element={<WorksSpelltorn />}
        />

        <Route
          path={WorksUrlTokens.street}
          element={<WorksStreet />}
        />

        <Route
          path={WorksUrlTokens.capitalCity}
          element={<WorksCapitalCity />}
        />

        <Route
          path={WorksUrlTokens.mcgrathsOffroad}
          element={<WorksMcGrathsOffroad />}
        />

        <Route
          path={WorksUrlTokens.mxOffroad}
          element={<WorksMxOffroad />}
        />

        <Route
          path={WorksUrlTokens.mxMatchup}
          element={<WorksMxMatchup />}
        />

        <Route
          path={WorksUrlTokens.tapper}
          element={<WorksTapper />}
        />

        <Route
          path={WorksUrlTokens.hyundaiTimeSquare}
          element={<WorksHyundaiTimeSquare />}
        />

        <Route
          path={WorksUrlTokens.trophylite}
          element={<WorksTrophyLite />}
        />

        <Route
          path={WorksUrlTokens.snocross}
          element={<WorksSnocross />}
        />

        <Route
          path={WorksUrlTokens.hyundaiVeloster}
          element={<WorksHyundaiVeloster />}
        />

        <Route
          path={WorksUrlTokens.fleetDefense}
          element={<WorksFleetDefense />}
        />

        <Route
          path={WorksUrlTokens.atvOffroad}
          element={<WorksAtvOffroad />}
        />

        <Route
          path={WorksUrlTokens.wavesOfDestruction}
          element={<WorksWavesOfDestruction />}
        />

        <Route
          path={WorksUrlTokens.squareDestroyer}
          element={<WorksSquareDestroyer />}
        />

        <Route
          path={WorksUrlTokens.talon}
          element={<WorksTalon />}
        />
      </Routes>
    </div>
  );
};
