import { FunctionComponent, ReactNode } from 'react';
import { FigCaption } from '../fig-caption';
import { FigHeader } from '../fig-header';
import classNames from 'classnames';

type PropsType = {
  src: string;
  alt?: string;
  header?: string | ReactNode;
  caption?: string | ReactNode;
  className?: string;
};

export const Image: FunctionComponent<PropsType> = ({
  src,
  alt,
  header,
  caption,
  className,
}) => {
  return (
    <>
      {header && (
        <FigHeader header={header} />
      )}
      
      <img
        className={classNames('generic-image', className)}
        src={src}
        alt={alt}
      />

      {caption && (
        <FigCaption caption={caption} />
      )}
    </>
  );
};
