import { useEffect } from 'react';
import './App.scss';
import { JGHeader } from './header';
import { JGFooter } from './footer';
import { HomepageTiles } from './homepage-tiles';
import { Route, Routes } from 'react-router';
import { Works } from './works';
import { ScrollToTopOfElement } from './pieces/scroll-to-top';
import { Blog } from './blog';
import Rss from './works/rss';
import { Resume } from './resume';
import { loadFirebase } from './firebase/firebase';
import { WorksUrlTokens } from './works/constants';
import { RSS_URL_TOKEN } from './works/rss/constants';
import { RESUME_URL_TOKEN } from './resume/constants';
import { BLOG_URL_TOKEN } from './blog/constants';
import MyPackageInfo from '../package.json';
import './polyfills.ts';

function App() {
  loadFirebase();

  useEffect(() => {
    console.log(`Version: ${MyPackageInfo.version}`);
  }, []);

  return (
    <div className="App">
      <JGHeader />
    
      <div className="body">
        <ScrollToTopOfElement>
          <Routes>
            <Route
              path="/"
              element={<HomepageTiles />}
            />

            <Route
              path={`/${WorksUrlTokens.ROOT}/*`}
              element={<Works />}
            />

            <Route
              path={`/${BLOG_URL_TOKEN}/*`}
              element={<Blog />}
            />

            <Route
              path={`/${RESUME_URL_TOKEN}`}
              element={<Resume />}
            />

            <Route
              path={`/${RSS_URL_TOKEN}`}
              element={<Rss />}
            />
          </Routes>
        </ScrollToTopOfElement>
      </div>

      <JGFooter />
    </div>
  );
}

export default App;
