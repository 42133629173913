import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import busStopImage from './bus-stop-web.png';
import turnOrderImage from './turn-order.jpg';

import escapeFromSupercorpVideo from './Capital-City-Escape-from-Supercorp.mp4';
import escapeFromSupercorpVideoPoster from './Capital-City-Escape-from-Supercorp-poster.png';

const features = [
  'Interactive lessons from the famous Rich Dad team.',
  'A fun cartoon-like world.',
  'Multiplayer functionality capable of supporting simultaneous and asynchronous player actions.',
  'Android and iOS players are able to compete against each other.',
  'Leaderboards, in-game chat, world chat, and customizable player profiles.',
];

const contributions = [
  'Created a utility to easily script screen transitions and animate UI objects.',
  'Implemented code that supported the tutorial and coordinated with designers to ensure the tutorial flow would meet the needs of new players.',
  'Setup and revised in-game menus to meet the needs of the publisher.',
];

export const WorksCapitalCity: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Capital City | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Capital City"
          details={[
            'Developer: 2XL Games, Inc. for Torn Screen Entertainment, Inc.',
            'Released: 2014',
            'Platforms: iOS and Android',
            'Genre: Strategy, Education',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.googlePlay}
                url="https://web.archive.org/web/20151024130011/https://play.google.com/store/apps/details?id=com.tornscreen.ccgame01&hl=en"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Features"
          list={features}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=mAti2MN-R5s
          src={escapeFromSupercorpVideo}
          caption="Capital City trailer"
          poster={escapeFromSupercorpVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={busStopImage}
          caption="Properties on corners are more likely to be visited but are not always a wise investment."
        />
      </Container>

      <Container centerText>
        <Image
          src={turnOrderImage}
          caption="The turn order. The game was a digital boardgame. You could test the game design with pen, paper, and dice."
        />
      </Container>
    </div>
  );
};
