import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.jpg';
import racingMiniImage from './racing-mini-2.jpg';
import upgradesImage from './upgrades.jpg';
import mclarenImage from './showroom-mclaren-p1.jpg';
import turfTutorialImage from './turf-tutorial.jpg';
import racingTrailerVideo from './2XL-Racing-Trailer.mp4';
import racingTrailerVideoPoster from './2XL-Racing-Trailer-poster.png';

const features = [
  'Realtime, online, multiplayer gameplay with social integration and leaderboards.',
  'Highly detailed, licensed vehicles with options for customizations and performance boosts.',
];

const contributions = [
  'Implemented extensive revenue and user action tracking for perspective publisher.',
  'Assisted in implementing early concepts for guidance tutorials.',
  'Implemented display control for quick-race results screen.',
];

export const WorksStreet: FunctionComponent = () => {
  useEffect(() => {
    document.title = "2XL Racing | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL Racing"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2014',
            'Platforms: iOS and Android',
            'Genre: Racing / Online matchup',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.googlePlay}
                url="https://web.archive.org/web/20201026083230/https://play.google.com/store/apps/details?id=com.twoxlgames.street&hl=en"
              />

              <AppLink
                appType={AppType.appleAppStore}
                url="https://itunes.apple.com/au/app/2xl-racing-street-wars/id797136411?mt=8"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="http://2xlgames.com/2xlracing"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Features"
          list={features}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=2-RZfWABozY
          src={racingTrailerVideo}
          caption="2XL Racing trailer"
          poster={racingTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={racingMiniImage}
          header="The gameplay primarily revolves around testing the player's reaction time to shift gears at the right time."
        />
      </Container>

      <Container centerText>
        <Image
          src={upgradesImage}
          header="You can purchase all sorts of upgrades for your vehicles. These kind of in-app purchases are common for freemium mobile games."
          caption="Upgrade screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={mclarenImage}
          header="We had plenty of amazing vehicles for racing."
        />
      </Container>

      <Container centerText>
        <Image
          src={turfTutorialImage}
          header="The single player mode tasks the player with racing to claim territory."
        />
      </Container>
    </div>
  );
};
