import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { WorkHeader } from '../../pieces/work-header';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import gmInnovationCenterVideo from './general-motors-innovation-center.mp4';
import gmInnovationCenterVideoPoster from './general-motors-innovation-center-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Used Java, HTML, CSS, JavaScript, and jQuery to develop an application for internal business customers with an annual direct business benefit of over $500,000.',
  'Worked with multiple data sources to create a stylized way of visualizing the data.',
  'Automated and simplified various processes that analyzed the global competitive landscape.',
  'Reduced executive report generation time from three months to just five days.',
  'Added functionality to the display for re-ordering, filtering, and changing display parameters.',
  'Worked on a distributed team with an expanding user base of global business customers.',
];

export const WorksGeneralMotors: FunctionComponent = () => {
  useEffect(() => {
    document.title = "General Motors | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="General Motors"
          position="Software Engineer"
          location="Chandler, AZ"
          details={[
            'July 2014 – August 2015',
            <WebLink
              linkText="GM"
              url="https://www.gm.com/"
            />
          ]}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=DpqoMIxUJEA
          src={gmInnovationCenterVideo}
          caption="I joined not long after the their new IT Innovation Center was finished in Chandler, Arizona."
          poster={gmInnovationCenterVideoPoster}
        />
      </Container>
    </div>
  );
};
