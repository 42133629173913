import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';
import gameStartImage from './game-start.png';
import gameplayImage from './gameplay.png';
import gameOverImage from './game-over.png';

export const WorksSquareDestroyer: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Square Destroyer | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Square Destroyer"
          details={[
            'Made in 2005',
          ]}
        />
      </Container>

      <Container>
        <p>
          I started developing this game while teaching myself C++ in high school. It is a remake of Tetris from scratch.
        </p>
      </Container>

      <Container centerText>
        <Image
          src={gameStartImage}
          header="The game just starts when running the EXE. If you have played Tetris then you are already familiar with the gameplay."
        />
      </Container>

      <Container centerText>
        <Image
          src={gameplayImage}
          header="The player has cleared one line and they have scored points. They are about to clear another line once this long piece falls and completes the lowest row."
        />
      </Container>

      <Container centerText>
        <Image
          src={gameOverImage}
          header="The pieces fall faster and faster. Eventually the speed is too much and the next piece can't enter the screen. The game is now over."
        />
      </Container>
    </div>
  );
};
