import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import pvpMatchupImage from './match-found-web.png';
import raceResultsImage from './race-results-0-web.png';
import riderUpgradesImage from './rider-upgrades-web.png';
import riderZombieImage from './rider-zombie-bob-web.png';
import mxMatchupTrailerVideo from './Ricky-Carmichael-Motocross-Matchup-Trailer.mp4';
import mxMatchupTrailerVideoPoster from './Ricky-Carmichael-Motocross-Matchup-Trailer-poster.png';

const contributions = [
  'Worked with artists to plan and implement screens.',
  'Created touch swipe controls that triggered the rider\'s stunt animation.',
  'Implemented parts of the character customization and in-game store.',
  'Upgradable vehicle and rider properties.',
  'Achievements unlocked through GameCenter on iOS devices.',
];

export const WorksMxMatchup: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Ricky Carmichael's Motocross Matchup | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Ricky Carmichael's Motocross Matchup"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2011',
            'Platforms: iOS and Android',
          ]}
          footer={(
            <>
              {/* <AppLink
                appType={AppType.googlePlay}
                url="https://play.google.com/store/apps/details?id=com.twoxlgames.mxmatchup"
              /> */}

              <AppLink
                appType={AppType.appleAppStore}
                url="https://itunes.apple.com/us/app/ricky-carmichaels-motocross-matchup-pro/id463869460?mt=8"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/20180916154646/http://2xlgames.com/rcmx/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=_EDOPVjkVWM
          src={mxMatchupTrailerVideo}
          caption="Motocross Matchup trailer"
          poster={mxMatchupTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={pvpMatchupImage}
          header="The game's primary attractions were the realtime matching feature and racing experience."
          caption="Matchup screen with Accept/Reject prompt"
        />
      </Container>

      <Container centerText>
        <Image
          src={raceResultsImage}
          header="Whether you won or lost, you earned wrenches(currency) towards upgrading your rider or purchasing new riders. You were scored on time-to-finish, stunts, and how many bonus boxes you found."
          caption="Results screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={riderUpgradesImage}
          header="Wrenches could be spent upgrading various stats."
          caption="Rider upgrade screen"
        />
      </Container>

      <Container centerText>
        <Image
          src={riderZombieImage}
          header="Our previous racing games featured standard riders but we thought players would enjoy more diversity. We added zombie, ninja, and cowgirl riders. Each with unique voice clips. Players could purchase special riders, such as Ricky Carmichael, that came with bonus stats."
          caption="Store screen"
        />
      </Container>
    </div>
  );
};
