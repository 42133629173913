import { FunctionComponent } from 'react';
import classNames from 'classnames';

type PropsType = {
  header: string;
  list: string[];
  columnCount?: number | string;
};

export const WorkList: FunctionComponent<PropsType> = ({
  header,
  list,
  columnCount,
}) => {
  return (
    <>
      <div className="work-list-header">
        {header}
      </div>

      <ul className={classNames('work-list-list', `col-count-${columnCount}`)}>
        {list.map((entry, index) => {
          return (
            <li key={index}>
              {entry}
            </li>
          );
        })}
      </ul>
    </>
  );
};
