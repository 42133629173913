import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';
import gameStartImage from './start.png';
import spacesImage from './spaces.png';
import playerTalkImage from './player-talk.png';
import collectPointsImage from './collect-points.png';

export const WorksTalon: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Talon | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Talon"
          details={[
            'Made in 2005',
          ]}
        />
      </Container>

      <Container>
        <p>
          I started developing this game while teaching myself C++ in high school. A friend showed me the ASCII version of Dwarf Fortress and it inspired me to try and create a basic adventure game.
        </p>
      </Container>

      <Container centerText>
        <Image
          src={gameStartImage}
          header="The game is technically two-player. One player uses the arrow keys and other player uses the number pad. There is a small blip sound that plays when moving around the screen. Finding a way to play an MP3 sound was a big deal."
        />
      </Container>

      <Container centerText>
        <Image
          src={spacesImage}
          header="Creating the different screens was fun and trying to get the coordinates to match when a player exits one screen and enters the next one was an interesting challenge."
        />
      </Container>

      <Container centerText>
        <Image
          src={playerTalkImage}
          header="When the two players are in adjacent squares they can talk to each other by pressing the spacebar or zero numberpad key."
        />
      </Container>

      <Container centerText>
        <Image
          src={collectPointsImage}
          header="The diamonds can be collected for points."
        />
      </Container>
    </div>
  );
};
