import { FunctionComponent } from 'react';

type PropsType = {
  children?: React.ReactNode;
};

export const BlogEntryParagraphBox: FunctionComponent<PropsType> = ({
  children,
}) => {
  return (
    <div className="blog-entry-paragraph-box">
      {children}
    </div>
  );
};
