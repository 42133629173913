import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getCloudFunctionUrl } from '../../firebase/firebase';
import { RssListItem } from './rss-list-item';
import { addEntryToSavedRssEntries, cleanupStringOfSpecialCharacters, clearHiddenRssStorage,
  dismissRssDescriptionMessage,
  filterRssEntriesAgainstHiddenUrls, getHiddenUrls, getSavedRssEntries, hideRssLinkUrl, isRssDescriptionMessageDismissed, processRssResponse,
  removeUrlFromSavedRssEntries,
  RSS_SOURCES,
  RssEntryData,
  RssSource,
} from './rss-utils';
import classNames from 'classnames';
import { RssCloseButton } from './rss-close-button';

function Rss() {
  useEffect(() => {
    document.title = "RSS | Joe Gosselin";
 }, []);

  const [allRssEntries, setAllRssEntries] = useState<RssEntryData[]>([]);
  const [displayRssEntries, setDisplayRssEntries] = useState<RssEntryData[]>([]);
  const [savedRssEntries, setSavedRssEntries] = useState<RssEntryData[]>([]);
  const [hasHiddenEntries, setHasHiddenEntries] = useState(false);
  const [mostRecentlyHiddenUrl, setMostRecentlyHiddenUrl] = useState('');

  const [isMessageDismissed, setIsMessageDismissed] = useState(true);

  const [pendingRequests, setPendingRequests] = useState(0);

  const incrementPendingRequests = useCallback(() => {
    setPendingRequests((prevState) => prevState + 1);
  }, []);

  const decrementPendingRequests = useCallback(() => {
    setPendingRequests((prevState) => prevState - 1);
  }, []);

  const checkHasHiddenEntries = useCallback(() => {
    const hasHidden = getHiddenUrls().length > 0

    setHasHiddenEntries(hasHidden);
  }, []);

  const removeHiddenRssEntries = useCallback(() => {
    const newRssEntries = filterRssEntriesAgainstHiddenUrls(displayRssEntries);

    setDisplayRssEntries(newRssEntries);
  }, [displayRssEntries, setDisplayRssEntries]);

  const hideLinkUrl = useCallback((linkUrl: string) => {
    hideRssLinkUrl(linkUrl);
    removeHiddenRssEntries();
    checkHasHiddenEntries();
    setMostRecentlyHiddenUrl(linkUrl);
  }, [removeHiddenRssEntries, checkHasHiddenEntries]);

  const getRssEntriesSorted = useCallback((): RssEntryData[] => {
    return displayRssEntries
      // Sort newest-to-oldest.
      .sort((objA, objB) => objB.date.getTime() - objA.date.getTime());
  }, [displayRssEntries]);

  const getRssSavedEntriesSorted = useCallback((): RssEntryData[] => {
    return savedRssEntries
      // Sort newest-to-oldest.
      .sort((objA, objB) => objB.date.getTime() - objA.date.getTime());
  }, [savedRssEntries]);

  const requestRssDataDirectly = useCallback((url: string): Promise<string | null> => {
    return axios.get<string>(url)
      .then((responseData) => {
        return responseData.data;
      }).catch((err) => {
        console.error(err);
        return null;
      });
  }, []);

  // TODO - Remove this once migrating to Firebase hosting.
  // const requestRssDataViaPhp = useCallback((url: string): Promise<string | null> => {
  //   const requestUrl = `http://www.joe-gosselin.com/rssData.php?rssUrl=${encodeURIComponent(url)}`;
  //   return axios.get<string>(requestUrl)
  //     .then((responseData) => {
  //       return responseData.data;
  //     }).catch(() => {
  //       return null;
  //     });
  // }, []);

  const requestRssDataViaFirebase = useCallback((url: string): Promise<string | null> => {
    const requestUrl = `${getCloudFunctionUrl('rssData')}?rssUrl=${encodeURIComponent(url)}`;
    return axios.get<string>(requestUrl)
      .then((responseData) => {
        return responseData.data;
      }).catch(() => {
        return null;
      });
  }, []);

  const requestRssData = useCallback((rssSource: RssSource): Promise<RssEntryData[]> => {
    return requestRssDataDirectly(rssSource.url)
      .then((responseDataString) => {
        return (responseDataString === null ? requestRssDataViaFirebase(rssSource.url) : Promise.resolve(responseDataString))
          .then((finalResponseDataString) => {
            let stringWip = finalResponseDataString || '';

            stringWip = cleanupStringOfSpecialCharacters(stringWip);
            return processRssResponse(stringWip, rssSource);
          }).catch((err) => {
            console.error(err);
            return [];
          });
      }).catch((err) => {
        console.error(err);
        return [];
      });
  }, [requestRssDataDirectly, requestRssDataViaFirebase]);

  const requestAllRssData = useCallback((): void => {
    const allNewEntries: RssEntryData[] = [];

    RSS_SOURCES.forEach((rssSource) => {
      incrementPendingRequests();
      
      requestRssData(rssSource)
        .then((newRssEntries) => {
          if (newRssEntries.length > 0) {
            allNewEntries.push(...newRssEntries);

            setAllRssEntries(allNewEntries);
            setDisplayRssEntries(filterRssEntriesAgainstHiddenUrls(allNewEntries));
          }
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          decrementPendingRequests();
        });
    });
  }, [decrementPendingRequests, incrementPendingRequests, requestRssData]);

  const collectedSavedItems = useCallback((): void => {
    const savedItems = getSavedRssEntries();
    setSavedRssEntries(savedItems);
  }, []);

  useEffect(() => {
    collectedSavedItems();
    checkHasHiddenEntries();
    requestAllRssData();
  }, [collectedSavedItems, requestAllRssData, checkHasHiddenEntries]);

  const handeleClickUnhideAll = useCallback((): void => {
    clearHiddenRssStorage();
    setDisplayRssEntries(allRssEntries);
    checkHasHiddenEntries();
    setMostRecentlyHiddenUrl('');
  }, [allRssEntries, setDisplayRssEntries, checkHasHiddenEntries]);

  const handleClickUnhideMostRecent = useCallback((): void => {
    const entryToRestore = allRssEntries.find((entry) => {
      return entry.linkUrl === mostRecentlyHiddenUrl;
    });

    setMostRecentlyHiddenUrl('');

    if (entryToRestore) {
      const newDisplayEntries = displayRssEntries.slice();
      newDisplayEntries.push(entryToRestore);
      setDisplayRssEntries(newDisplayEntries);
    }
  }, [allRssEntries, displayRssEntries, mostRecentlyHiddenUrl]);

  const handleClickCloseEntryButton = useCallback((entry: RssEntryData): void => {
    hideLinkUrl(entry.linkUrl);
  }, [hideLinkUrl]);

  const handleClickCloseSavedEntryButton = useCallback((entry: RssEntryData): void => {
    removeUrlFromSavedRssEntries(entry.linkUrl);

    collectedSavedItems();
  }, [collectedSavedItems]);

  const handleClickSaveButton = useCallback((entry: RssEntryData): void => {
    // Add to saved items.
    addEntryToSavedRssEntries(entry, savedRssEntries);

    // Remove from list of items.
    hideLinkUrl(entry.linkUrl);

    collectedSavedItems();
  }, [savedRssEntries, hideLinkUrl, collectedSavedItems]);

  const checkIfMessageDismissed = useCallback(() => {
    const isDismissed = isRssDescriptionMessageDismissed();
    setIsMessageDismissed(isDismissed);
  }, []);

  useEffect(() => {
    checkIfMessageDismissed();
  }, [checkIfMessageDismissed]);

  const handleClickCloseDescriptionMessageButton = useCallback(() => {
    dismissRssDescriptionMessage();
    checkIfMessageDismissed();
  }, [checkIfMessageDismissed]);

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const savedRssEntriesSorted = getRssSavedEntriesSorted();
  const rssEntriesSorted = getRssEntriesSorted();

  const focusNextRssItem = useCallback(() => {
    if (rssEntriesSorted.length === 0) {
      setFocusedIndex(-1);
      return;
    }

    if (focusedIndex < 0) {
      setFocusedIndex(0);
      return;
    }

    const newFocusIndex = focusedIndex + 1;

    if (newFocusIndex >= rssEntriesSorted.length) {
      return;
    }

    setFocusedIndex(newFocusIndex);
  }, [focusedIndex, rssEntriesSorted.length]);

  const focusPreviousRssItem = useCallback(() => {
    if (rssEntriesSorted.length === 0) {
      setFocusedIndex(-1);
      return;
    }

    if (focusedIndex < 0) {
      setFocusedIndex(0);
      return;
    }

    const newFocusIndex = focusedIndex - 1;

    if (newFocusIndex < 0) {
      return;
    }

    setFocusedIndex(newFocusIndex);
  }, [focusedIndex, rssEntriesSorted.length]);

  const deleteFocusedRssItem = useCallback(() => {
    if (focusedIndex < 0 || focusedIndex >= rssEntriesSorted.length) {
      return;
    }

    const focusedEntry = rssEntriesSorted[focusedIndex];
    hideLinkUrl(focusedEntry.linkUrl);

    if (rssEntriesSorted.length === 0) {
      // Removing the only means nothing is focused.
      setFocusedIndex(-1);
    } else if (focusedIndex === rssEntriesSorted.length - 1) {
      // Removing the last entry means we focus on the second-to-last entry.
      setFocusedIndex(focusedIndex - 1);
    }
  }, [focusedIndex, hideLinkUrl, rssEntriesSorted]);

  const handleKeyDown = useCallback((e: any) => {
    if (e.key === 'ArrowDown') {
      focusNextRssItem();
    } else if (e.key === 'ArrowUp') {
      focusPreviousRssItem();
    } else if (e.key === 'Delete') {
      deleteFocusedRssItem();
    }
  }, [deleteFocusedRssItem, focusNextRssItem, focusPreviousRssItem]);

  return (
    <div className="rss" tabIndex={0} onKeyDown={handleKeyDown}>
      {!isMessageDismissed && (
        <div className="description-box">
          <div className="description-message">
            I created this RSS reader to help browse recent news and entertainment. A panopticon of sorts unhindered by advertisements or popups.
          </div>

          <RssCloseButton
            className="rss-button close-top-message"
            onClick={handleClickCloseDescriptionMessageButton}
          />
        </div>
      )}

      <div className="request-info-box">
        <div className={classNames('pending-requests-container', {
          'no-pending-requests': pendingRequests === 0,
        })}>
          (Pending: {pendingRequests})
        </div>

        <div className="unhide-buttons-container">
          <div
            className={classNames('unhide-button is-clickable rss-button', { 'is-hidden': !mostRecentlyHiddenUrl})}
            onClick={handleClickUnhideMostRecent}
          >
            <span>
              Undo
            </span>
          </div>

          <div
            className={classNames('unhide-button is-clickable rss-button', { 'is-hidden': !hasHiddenEntries})}
            onClick={handeleClickUnhideAll}
          >
            <span>
              Unhide All
            </span>
          </div>
        </div>
      </div>

      <div className="rss-list">
        <>
          {rssEntriesSorted.map((entry, rssIdx) => {
            return (
              <RssListItem
                key={entry.linkUrl}
                title={entry.title}
                description={entry.description}
                linkUrl={entry.linkUrl}
                downloadUrl={entry.downloadUrl}
                imgSrc={entry.imgSrc}
                date={entry.date}
                buttonClassName="rss-button"
                onClickCloseButton={() => {
                  handleClickCloseEntryButton(entry);
                }}
                onClickSaveButton={() => {
                  handleClickSaveButton(entry);
                }}
                isFocused={rssIdx === focusedIndex}
              />
            );
          })}
        </>

        {savedRssEntriesSorted.length > 0 && (
          <div className="saved-items-container">
            <div className="saved-section-label">Saved:</div>

            <>
              {savedRssEntriesSorted.map((entry) => {
                return (
                  <RssListItem
                    key={entry.linkUrl}
                    title={entry.title}
                    description={entry.description}
                    linkUrl={entry.linkUrl}
                    downloadUrl={entry.downloadUrl}
                    imgSrc={entry.imgSrc}
                    date={entry.date}
                    buttonClassName="rss-button"
                    onClickCloseButton={() => {
                      handleClickCloseSavedEntryButton(entry);
                    }}
                  />
                );
              })}
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default Rss;
