import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';
import mapTripHistoryScrubberImage from './trip-history-scrubber.png';
import mapPerformancePointsViolationsImage from './map-performance.png';
import mapWeatherImage from './map-weather.png';
import scheduledReportsEditImage from './scheduled-reports-edit.png';
import harwareVerificationImage from './hardware-verification-check-inputs.png';
import gpsDevicesImage from './gps-devices.jpg';
import employeeOfMonthImage from './Joe-Gosselin-Employee-of-the-Month-2021-05-07.png';
import earthDayImage from './earth-day-2022-cropped.png';
import starvingChildrenImage from './feed-my-starving-children-2019.png';
import favoritesPt1Image from './favorites-pt1.png';
import favoritesPt2Image from './favorites-pt2.png';
import favoritesPt3Image from './favorites-pt3.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Worked on a new web app using React and PHP to replace an older app',
  'Conducted thorough code reviews and provided feedback on change requests',
  'Implemented Drift in the frontend for live customer service chat and automated playbooks',
  'Refactored Map code for major performance gains. Potentially saved our largest customer',
  'Collaborated with the hardware installation team to create a hardware installation verification web app',
  'Worked with a Director of Engineering to update an application used to generate data for demonstrations',
  'Assisted Support personnel with debugging and understanding expected application behavior',
  'Implemented majority of new web app report forms',
];

const toolsUtils = [
  'VS Code',
  'React (JS / TypeScript)',
  'CSS / Less',
  'PHP',
  'Google Maps JavaScript API',
  'Redux dev tools',
  'GitLab (git)',
  'Postman',
  'SQL / GraphQL',
  'DBeaver / DataGrip',
  'TestCafe',
  'Jest (Mocha / Chai)',
  'PHPUnit',
  'Strapi',
  'Jira',
  'Basecamp',
  'Figma',
  'Ant Design',
  'Drift / Intercom',
  'Storybook',
];

export const WorksGpsInsight: FunctionComponent = () => {
  useEffect(() => {
    document.title = "GPS Insight | Joe Gosselin";
 }, []);

  return (
    <div className="gps-insight-work">
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="GPS Insight"
          position="Senior Developer"
          location="Scottsdale, AZ"
          details={[
            'August 2019 – Present',
            'Duties primarily in the frontend with intermittent API, backend, and QA work as necessary',
            (
              <WebLink
                linkText="GPS Insight"
                url="https://www.gpsinsight.com/"
              />
            )
          ]}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container>
        <WorkList
          header="Languages / Tools / Libraries"
          list={toolsUtils}
          columnCount="dynamic"
        />
      </Container>

      <Container centerText>
        <Image
          src={mapTripHistoryScrubberImage}
          alt="Trip History Scrubber"
          header="I implemented a scrubber for our Trip History feature. A user could zoom into a section of the timeline to quickly see when a trip started, stopped, and when a driving violation occurred."
        />
      </Container>

      <Container centerText>
        <Image
          src={mapPerformancePointsViolationsImage}
          alt="Historic points performance improvements"
          header="Our largest customers have thousands of vehicles and map performance had become a topic of great concern. I measured performance and found multiple places where the code could be refactored. Customers were thrilled when that update was deployed."
          caption="Vehicle trip history showing a day full of activity with a plethora of speeding violations"
        />
      </Container>

      <Container>
        <div className="title-text">
          Favorites - Hack Week
        </div>

        <p className="paragraph-with-indent">
          I pitched and constructed a "Favorites" proof-of-concept (PoC) as part of a Hack Week competition. The competition involved a slew of great ideas and projects from various teams around the globe. I was proud that the "Favorites" project placed third in the competition and gratified to hear that another team was inspired to add a similar feature into their product. I ended my presentation with a list of Next Steps needed to get the PoC ready for production and mentioned which parts of our tech stack would be affected. I estimated that it could be fully implemented within one quarter. It was greenlit for inclusion within our New Portal web app at a future date.
        </p>

        <p>
          This was our first Hack Week and I appreciated this opportunity to pitch an idea to improve the product, improve the experience, and create something that further differentiates us from our competitors.
        </p>

        <br/>

        <div>Summary of the pitch:</div>
        <ul>
          <li>
            Time is money. It doesn't matter if you are a customer, developer, tester, or salesperson. Getting to what you want faster has great value.
          </li>

          <li>
            Our app can allow a manager to setup weblinks within Portal that are then shared with other Portal users on their account. This can help reduce complexity in their business by providing a space to share resources.
          </li>

          <li>
            There are a variety of accounts that have been setup specifically for testing. The QA team could benefit from the new Favorites feature within our account management portal. Developers usually keep a list of accounts that they know are good to use when working on features specific to a product tier or accounts with many vehicles for stress-testing updates.
          </li>
        </ul>

        <br />
        <br />

        <Image
          src={favoritesPt1Image}
          header={`The main use case for "Favorites" was allowing a user to add various entities to a list of favorites that could be quickly selected on the Map. This could help a fleet manager keep a close eye on new drivers or vehicles that were carrying precious cargo. I also added the ability to favorite reports on the Reports Dashboard page. We had over 40 different report types at the time and most users commonly interact with only a few on a regular basis. Having your favorite reports front and center would save time.`}
        />

        <br />
        <br />
        <br />

        <Image
          src={favoritesPt2Image}
          header={`The "Favorites" PoC allowed a user to setup weblinks. The intention was that if the user commonly interacted with a windshield repair service like Safelite AutoGlass then there could be a direct link to them in the Portal. An admin user could setup weblinks to be shared with other users. This would help a company onboard new users more quickly by linking to resources they may need to perform their duties.`}
        />

        <br />
        <br />
        <br />

        <Image
          src={favoritesPt3Image}
          header={`The weblinks could be accessed from anywhere in the app using the App Switcher tool in the upper-left corner of the screen. I could see this being helpful if you needed quick access to a time zone converter, a miles-to-kilometers calculator, an incident reporting tool, or a link to submit time sheets.`}
        />
      </Container>

      <Container centerText>
        <Image
          src={mapWeatherImage}
          alt="Map Weather Overlay"
          caption="When planning a trip or dispatching drivers, knowing the weather and forecast can be valuable info. I implemented the weather overlay and predictive weather features."
        />
      </Container>

      <Container centerText>
        <Image
          src={scheduledReportsEditImage}
          alt="Editing Scheduled Reports"
          header="After a re-organization of the development teams I took on frontend reporting responsibilities. Part of those new responsibilities included creating, editing, and running Scheduled Reports in our new application."
        />
      </Container>

      <Container centerText>
        <Image
          src={harwareVerificationImage}
          alt="Hardware Verification app - Check inputs"
          header={
            <div>The hardware verification app was a lot of fun to work on. I was able to work with colleagues from the Installations department and experience a new side of our company. The original acceptance criteria only called for text descriptions when verifying inputs. I added images from our Help Center when demoing progress and Installations loved it because it aided self-installs.</div>
          }
          caption={
            <WebLink
              linkText="Go to the App"
              url="https://verifygps.gpsinsight.com/"
            />
          }
        />
      </Container>

      <Container centerText>
        <Image
          src={gpsDevicesImage}
          header="GPS Insight supports a variety of device types. Fortunately, any device-specific considerations or complexities are abstracted away before the data reaches any of the data sources that I consume."
        />
      </Container>

      <Container centerText>
        <Image
          src={employeeOfMonthImage}
          alt="Employee of the Month - April 2021"
          header="I was awarded Employee of the Month in April of 2021."
        />
      </Container>

      <Container centerText>
        <Image
          src={earthDayImage}
          alt="Volunteer - Earth Day Road Clean-up 2022"
          caption={<div>
            <div>We have an annual roadside clean-up for Earth Day. I regularly volunteer and we help clean the road along a park, school, and post office.</div>
            <div>I am the one on the far left wearing sunglasses.<span className="no-italics">😎</span></div>
          </div>}
        />
      </Container>

      <Container centerText>
        <Image
          src={starvingChildrenImage}
          alt="Volunteer - Feed My Starving Children 2019"
          caption={(
            <>
              <div>
                In September 2019 I volunteered with GPS Insight to help pack food for people in need. We packed 51,624 meal boxes to feed 141 kids for an entire year. The meals were destined for a community located in the Philippines.
              </div>

              <br />

              <WebLink
                linkText="https://www.fmsc.org/"
                url="https://www.fmsc.org/"
              />
            </>
          )}
        />
      </Container>
    </div>
  );
};
