import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export type HomepageTilePropsType = {
  text: React.ReactNode;
  linkUrl: string;
  imagePath: string;
  mouseoverImagePath: string | null;
};

export const HomepageTile: FunctionComponent<HomepageTilePropsType> = ({
  text,
  imagePath,
  mouseoverImagePath,
  linkUrl
}) => {
  return (
    <div className="homepage-tile">
      <Link
        className="link"
        to={linkUrl}
      >
        <div className="image-container">
          {/* Standard image. */}
          <img
            className="homepage-tile-image image-standard"
            src={imagePath}
            alt=""
          />

          {/* Hover image. */}
          <img
            className="homepage-tile-image image-hover"
            src={mouseoverImagePath || imagePath}
            alt=""
          />
        </div>

        <span className="text">
          {text}
        </span>
      </Link>
    </div>
  );
};
