import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';

import headerIcon from './icon.png';
import startPlacingImage from './start-placing.png';
import confirmPlacementImage from './confirm-placement.png';
import aiAttackLogic1Image from './ai-attack-logic-1.png';
import aiAttackLogic2Image from './ai-attack-logic-2.png';
import playerWonImage from './player-won.png';
import { WebLink } from '../../pieces/web-link';

export const WorksWavesOfDestruction: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Waves of Destruction | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Waves of Destruction"
          details={[
            'Made in 2005',
          ]}
        />
      </Container>

      <Container>
        <p>
          I started developing this game shortly before the start of my freshman year of college. It is essentially a clone of the board game Battleship by Milton Bradley&nbsp;
          <WebLink
            linkText="wiki"
            url="https://en.wikipedia.org/wiki/Battleship_(game)"
          />
          . This project provided the opportunity to experiment with a basic A.I. opponent.
        </p>
      </Container>

      <Container centerText>
        <Image
          src={startPlacingImage}
          header="When the game is launched, the player is tasked with placing their ships. The keyboard arrow keys move the ship and the spacebar rotates it. Pressing enter places the ship and proceeds to placing the next ship."
        />
      </Container>

      <Container centerText>
        <Image
          src={confirmPlacementImage}
          header="The player is prompted to confirm placement after placing the final ship. They can replace any ship or press zero to continue. This image shows a bug where I wasn't repainting colored board squares correctly."
          caption="Game setup"
        />
      </Container>

      <Container centerText>
        <Image
          src={aiAttackLogic1Image}
          header="It would be fairly easy to have the computer opponent attack random coordinates but a more realistic opponent wouldn't attack the same spot twice. Also, a smart opponent would keep attacking an area after registering a hit."
        />
      </Container>

      <Container centerText>
        <Image
          src={aiAttackLogic2Image}
          header="It was deeply satisfying once this basic opponent logic was implemented."
        />
      </Container>

      <Container centerText>
        <Image
          src={playerWonImage}
          header="The game is over once one of the players has sunk all of their opponent's ships."
          caption="Game over"
        />
      </Container>
    </div>
  );
};
