import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import mainMenuImage from './main-menu-web.png';
import raceStartImage from './gameplay-1-web.png';
import scnocrossGameTrailerVideo from './X-Games-SnoCross-iPhone-Game-Trailer.mp4';
import scnocrossGameTrailerVideoPoster from './X-Games-SnoCross-iPhone-Game-Trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Implemented UI flow.',
  'Achievements system updates.',
  'Setup audio triggers for button-press feedback.',
  'The time frame was very demanding. We had little time to finish the game and receive Apple\'s App Store approval in order to be available for sale before the X Games began.',
];

export const WorksSnocross: FunctionComponent = () => {
  useEffect(() => {
    document.title = "X Games SnoCross | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="X Games SnoCross"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2010',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.appleAppStore}
                url="https://itunes.apple.com/us/app/2xl-snocross/id589220663?mt=8"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/20181218152533/http://2xlgames.com/snocross/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=mKh_HwsR_FY
          src={scnocrossGameTrailerVideo}
          caption="X Games SnoCross iPhone Game Trailer"
          poster={scnocrossGameTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={mainMenuImage}
          header="The ticker text along the bottom was retrieved from ESPN's online service to help keep players up to date on the events. The ESPN logo could be pressed to open the article in a browser."
          caption="Ticker"
        />
      </Container>

      <Container centerText>
        <Image
          src={raceStartImage}
          caption="Race start lights"
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            TouchArcade - "2XL Games Delivers ESPN Branded ‘X Games SnoCross’" - January 18, 2010&nbsp;
            <WebLink
              linkText="link"
              url="https://toucharcade.com/2010/01/18/2xl-games-delivers-espn-branded-x-games-snocross/"
            />
          </li>

          <li>
            iPhone Games Network - "2XL Snocross Game Review" - January 31, 2023&nbsp;
            <WebLink
              linkText="link"
              url="https://www.iphonegamenetwork.com/2xl-snocross-game-review/"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
