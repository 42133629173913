import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { IconFloppy } from '../../../pieces/icon-floppy';
import { RssEntryData, clickTemporaryAnchor } from '../rss-utils';
import { RssCloseButton } from '../rss-close-button';
import classNames from 'classnames';
import { IconDownload } from '../../../pieces/icon-download';

type PropsType = RssEntryData & {
  buttonClassName?: string;
  onClickCloseButton?: () => void;
  onClickSaveButton?: () => void;
  isFocused?: boolean;
};

export const RssListItem: FunctionComponent<PropsType> = ({
  title = '',
  description = '',
  linkUrl,
  downloadUrl,
  imgSrc,
  date,
  buttonClassName,
  onClickCloseButton,
  onClickSaveButton,
  isFocused,
}) => {
  const longTitle = (title || '').substring(0, 200);
  const shortTitle = longTitle.substring(0, 140);

  const longDescription = (description || '').substring(0, 1000);
  const shortDescription = longDescription.substring(0, 300);

  const containerElemRef = useRef<HTMLDivElement>(null);

  const handleClickDownloadLink = useCallback((e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if (!downloadUrl) {
      return;
    }

    clickTemporaryAnchor(downloadUrl);
  }, [downloadUrl]);

  useEffect(() => {
    if (!isFocused || !containerElemRef || !containerElemRef.current) {
      return;
    }

    // Scroll the focused element into view.
    containerElemRef.current.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }, [isFocused]);

  return (
    <div className={classNames('rss-list-item', {
      'is-focused': isFocused,
    })}
    ref={containerElemRef}
    >
      <div className="body-container">
        <a
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="rss-list-item-body">
            {imgSrc && (
              <img className="image" src={imgSrc} alt={shortTitle} />
            )}

            <div className="right-side">
              <span
                className="title"
                title={longTitle}
              >
                {shortTitle}
              </span>

              {shortDescription && (
                <>
                  <br />
                  <span
                    className="description"
                    title={longDescription}
                  >
                    {shortDescription}
                  </span>
                </>
              )}

              {date && (
                <span className="date">&nbsp;({date.toLocaleString()})</span>
              )}
            </div>
          </div>
        </a>
      </div>

      <div className="right-column">
        <div>
          {onClickCloseButton && (
            <RssCloseButton
              className={buttonClassName}
              onClick={onClickCloseButton}
            />
          )}
        </div>

        <div className="save-and-download-buttons">
          {downloadUrl && (
            <a
              href={downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClickDownloadLink}
              title="Download"
            >
              <div
                className={classNames(buttonClassName, 'download-button', 'is-clickable')}
              >
                <IconDownload
                  fillColor='#fff'
                  size={16}
                />
              </div>
            </a>
          )}

          {onClickSaveButton && (
            <div
              className={classNames(buttonClassName, 'save-button', 'is-clickable')}
              onClick={onClickSaveButton}
              title="Save"
            >
              <span title="Save">
                <IconFloppy
                  fillColor='#fff'
                  size={20}
                />
                </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
