import { FunctionComponent, ReactNode } from 'react';

type PropsType = {
  caption: string | ReactNode;
};

export const FigCaption: FunctionComponent<PropsType> = ({
  caption,
}) => {
  return (
    <figcaption className="fig-caption">
      {caption}
    </figcaption>
  );
};
