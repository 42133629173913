import { FunctionComponent, ReactNode } from 'react';
import { PageHeaderImage } from '../page-header-image';

type PropsType = {
  iconPath?: string;
  header: string;
  position?: string;
  location?: string;
  details?: string[] | ReactNode[];
  footer?: ReactNode;
};

export const WorkHeader: FunctionComponent<PropsType> = ({
  iconPath,
  header,
  position,
  location,
  details,
  footer,
}) => {
  return (
    <div className="work-header">
      <div className="container">
        <PageHeaderImage
          iconPath={iconPath}
          header={header}
        />

        <div className="right-column">
          <div className="header">
            {header}
          </div>

          <div className="right-column-body">
            {(position || location) && (
              <div className="position-and-location-container">
                {position && (
                  <span className="position work-header-line-height">
                    {position}
                  </span>
                )}

                {location && (
                  <span className="work-header-line-height">
                    {position ? ' | ' : null}
                    {location}
                  </span>
                )}
              </div>
            )}

            {details?.map((entry, index) => (
              <div key={index} className="work-header-line-height">
                {entry}
              </div>
            ))}
          </div>
        </div>
      </div>

      {footer && (
        <div className="footer-container">
          {footer}
        </div>
      )}
    </div>
  );
};
