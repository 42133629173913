const HIDDEN_URLS_NAME = 'jgRssHiddenUrls';
const SAVED_ITEMS_NAME = 'jgRssSavedItems';
const RSS_MESSAGE_DISMISSED_NAME = 'jgRssMessageDismissed';

export type RssSource = {
  url: string;
  displayTitlePrefix?: string;
  historyLimitMs?: number;
}

export const RSS_SOURCES: RssSource[] = [
  // {
  //   // Ars Technica.
  //   url: 'http://feeds.arstechnica.com/arstechnica/index',
  // },
  {
    // New York Times.
    url: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
    displayTitlePrefix: 'NYT',
    historyLimitMs: 1000 * 60 * 60 * 24 * 2, // 2 days.
  },
  {
    // NPR.
    url: 'https://feeds.npr.org/1002/rss.xml',
    displayTitlePrefix: 'NPR',
    historyLimitMs: 1000 * 60 * 60 * 24 * 2, // 2 days.
  },
  {
    // ABC15 News.
    url: 'https://www.abc15.com/news.rss',
    displayTitlePrefix: 'ABC15',
    historyLimitMs: 1000 * 60 * 60 * 24 * 2, // 2 days.
  },
  // {
  //   // Gamasutra.
  //   url: 'http://feeds.feedburner.com/GamasutraNews',
  // },
  // {
  //   // EFF.
  //   url: 'https://www.eff.org/rss/updates.xml',
  //   displayTitlePrefix: 'EFF',
  //   historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  // },
  {
    // The Onion.
    url: 'https://www.theonion.com/rss/regular',
    displayTitlePrefix: 'Onion',
    historyLimitMs: 1000 * 60 * 60 * 24 * 2, // 2 days.
  },
  {
    // Friendly Atheist Podcast.
    url: 'https://audioboom.com/channels/5049181.rss',
    displayTitlePrefix: 'FA',
    historyLimitMs: 1000 * 60 * 60 * 24 * 6, // 6 days.
  },
  // {
  //   // YT - GameGrumps.
  //   url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UC9CuvdOVfMPvKCiwdGKL3cQ',
  //   displayTitlePrefix: 'YT - GameGrumps',
  //   historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  // },
  {
    // YT - CaptainDisillusion.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCEOXxzW2vU0P-0THehuIIeg',
    displayTitlePrefix: 'YT - CaptainDisillusion',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - DidYouKnowGaming?.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCyS4xQE6DK4_p3qXQwJQAyA',
    displayTitlePrefix: 'YT - DidYouKnowGaming?',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - DidYouKnowGaming? 2.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCeCFik7Bl4JeEKHveT1cR1A',
    displayTitlePrefix: 'YT - DidYouKnowGaming? 2',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - Cinemassacre.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UC0M0rxSz3IF0CsSour1iWmw',
    displayTitlePrefix: 'YT - Cinemassacre',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - The Late Show with Stephen Colbert.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCMtFAi84ehTSYSE9XoHefig',
    displayTitlePrefix: 'YT - The Late Show',
    historyLimitMs: 1000 * 60 * 60 * 24 * 2, // 2 days.
  },
  {
    // YT - Internet Historian.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCR1D15p_vdP3HkrH8wgjQRw',
    displayTitlePrefix: 'YT - Internet Historian',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - Internet Historian: Incognito Mode.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UC8Q7XEy86Q7T-3kNpNjYgwA',
    displayTitlePrefix: 'YT - Internet Historian',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    // YT - LastWeekTonight.
    url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UC3XTzVzaHQEd30rQbuvCtTQ',
    displayTitlePrefix: 'YT - LastWeekTonight',
    historyLimitMs: 1000 * 60 * 60 * 24 * 8, // 8 days.
  },
  // {
  //   // YT - PostmodernJukebox.
  //   url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCORIeT1hk6tYBuntEXsguLg',
  //   displayTitlePrefix: 'YT - PostmodernJukebox',
  //   historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  // },
  // {
  //   // YT - A+Start.
  //   url: 'https://www.youtube.com/feeds/videos.xml?channel_id=UCcIe-_Hqzb3mAZyKEy1amDw',
  //   displayTitlePrefix: 'YT - A+Start',
  //   historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  // },
  {
    // This American Life.
    url: 'https://feed.thisamericanlife.org/talpodcast?format=xml',
    displayTitlePrefix: 'This American Life',
    historyLimitMs: 1000 * 60 * 60 * 24 * 4, // 4 days.
  },
  {
    url: 'https://feeds.buzzsprout.com/2040953.rss',
    displayTitlePrefix: 'If Books Could Kill',
    historyLimitMs: 1000 * 60 * 60 * 24 * 10, // 10 days.
  },
];

export type RssEntryData = {
  title: string;
  description?: string;
  imgSrc?: string;
  linkUrl: string;
  downloadUrl?: string;
  date: Date;
};

type HiddenUrl = {
  url: string;
  time: number;
};

export const cleanupStringOfSpecialCharacters = (stringToClean: string): string => {
  let returnStr = stringToClean.replace('\n', '')
    .replaceAll('“', '"')
    .replaceAll('”', '"')
    .replaceAll('&apos;', '\'')
    .replaceAll('&quot;', '"')
    .replaceAll('‘', '\'')
    .replaceAll('’', '\'')
    .replaceAll('&#x2018;', '\'')
    .replaceAll('&#x2019;', '\'')
    // Carriage return.
    .replaceAll('&#13;', ' ')
    .replaceAll('&#39;', '\'')
    .replaceAll('&#039;', '\'')
    .replaceAll('&amp;', '&');

  return returnStr;
};

export const clickTemporaryAnchor = (url: string, downloadFilenameWithExt?: string): void => {
  const a = document.createElement('a');

  if (downloadFilenameWithExt) {
    a.download = downloadFilenameWithExt;
  }

  a.href = url;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';

  const handleClickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(handleClickEvent);
  a.remove();
};


const cleanupDisplayString = (stringToClean: string): string => {
  let returnStr = cleanupStringOfSpecialCharacters(stringToClean)
    .replaceAll('\n', '')
    // .replaceAll('“', '"')
    // .replaceAll('”', '"')
    // .replaceAll('‘', '\'')
    // .replaceAll('’', '\'')
    // .replaceAll('&#x2018;', '\'')
    // .replaceAll('&#x2019;', '\'')
    // .replaceAll('&#039;', '\'')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    // .replaceAll('&quot;', '"')
    ;

  if (returnStr.indexOf('<![CDATA[') === 0) {
    returnStr = returnStr.split('<![CDATA[')[1];
    returnStr = returnStr.split(']]>')[0];
  }

  return returnStr;
};

// Includes the opening wicket.
// Return -1 if not found.
const getOpenTagStartIndex = (inputData: string, tagName: string, searchOffset?: number): number => {
  const nearestWholeTagIndex = inputData.indexOf(`<${tagName}>`, searchOffset);
  const nearestPartialTagIndex = inputData.indexOf(`<${tagName} `, searchOffset);

  if (nearestWholeTagIndex === -1
    && nearestPartialTagIndex === -1) {
    // Tag not found.
    return -1;
  }

  if (nearestWholeTagIndex !== -1
    && nearestPartialTagIndex === -1) {
    // Only whole tag found.
    return nearestWholeTagIndex;
  }

  if (nearestWholeTagIndex === -1
    && nearestPartialTagIndex !== -1) {
    // Only partial tag found.
    return nearestPartialTagIndex;
  }

  // Return whichever is closest.
  return Math.min(nearestWholeTagIndex, nearestPartialTagIndex);
};

const getCloseTag = (tagName: string): string => {
  return `</${tagName}>`;
};

const getCloseTagEndIndex = (inputData: string, tagName: string, searchOffset?: number): number => {
  const tagToFind = getCloseTag(tagName);
  const foundIndex = inputData.indexOf(tagToFind, searchOffset);

  if (foundIndex === -1) {
    // Not found.
    return -1;
  }

  // Found. Offset by searched string.
  return foundIndex + tagToFind.length;
};

const getCloseTagIndex = (inputData: string, tagName: string, searchOffset?: number): number => {
  const tagToFind = getCloseTag(tagName);
  return inputData.indexOf(tagToFind, searchOffset);
};

const getOpenTagLength = (inputData: string, tagName: string, searchOffset?: number): number => {
  const startIndex = getOpenTagStartIndex(inputData, tagName, searchOffset);

  if (startIndex === -1) {
    return 0;
  }

  const closeIndex = inputData.indexOf(`>`, startIndex);

  if (closeIndex === -1) {
    return 0;
  }

  return (closeIndex + 1) - startIndex;
};

const getCloseTagLength = (tagName: string): number => {
  const closeTag = getCloseTag(tagName);

  return closeTag.length;
};

const getTagData = (inputData: string, tagName: string, searchOffset?: number): string => {
  const tagStartIndex = getOpenTagStartIndex(inputData, tagName, searchOffset);

  if (tagStartIndex === -1) {
    return '';
  }

  let endIndex = getCloseTagEndIndex(inputData, tagName, tagStartIndex);

  // If we didn't find the end tag then this could be a self-closing tag.
  if (endIndex === -1) {
    const closingStr = '>';
    const selfClosingStr = '/>';
    const nearestClosing = inputData.indexOf(closingStr, tagStartIndex);
    const nearestSelfClosing = inputData.indexOf(selfClosingStr, tagStartIndex);

    if (nearestClosing !== -1
      && nearestSelfClosing !== -1
      && nearestSelfClosing < nearestClosing) {
      endIndex = nearestSelfClosing + selfClosingStr.length;
    }
  }

  if (endIndex === -1) {
    return '';
  }

  return inputData.substring(tagStartIndex, endIndex + 1);
};

const getDataBetweenTags = (inputData: string, tagName: string, searchOffset?: number): string => {
  let tagStartIndex = getOpenTagStartIndex(inputData, tagName, searchOffset);

  if (tagStartIndex === -1) {
    return '';
  }

  const startTagEndIndex = inputData.indexOf('>', tagStartIndex);

  if (startTagEndIndex === -1) {
    return '';
  }

  const dataStartIndex = startTagEndIndex + 1;
  const endTagStartIndex = inputData.indexOf(`</${tagName}`, dataStartIndex);

  if (endTagStartIndex === -1) {
    return '';
  }

  return inputData.substring(dataStartIndex, endTagStartIndex);
};

const removeDataByIndices = (inputData: string, startIndex: number, endIndex: number): string => {
  let returnData = inputData.substring(0, startIndex);
  returnData += inputData.substring(endIndex);

  return returnData;
};

const removeDataByIndexAndLength = (inputData: string, startIndex: number, length: number): string => {
  let returnData = inputData.substring(0, startIndex);
  returnData += inputData.substring(startIndex + length);

  return returnData;
};

// const removeTagData = (inputData: string, tagName: string): string => {
//   let returnData = inputData;

//   // Remove an opening tag.
//   const tagStartIndex = getOpenTagStartIndex(returnData, tagName);

//   if (tagStartIndex === -1) {
//     return returnData;
//   }

//   const openTagLength = getOpenTagLength(returnData, tagName, tagStartIndex);

//   if (openTagLength === -1) {
//     return returnData;
//   }

//   returnData = removeDataByIndices(returnData, tagStartIndex, tagStartIndex + openTagLength);

//   // Try removing a closing tag.
//   const tagEndIndex = getCloseTagIndex(returnData, tagName);

//   if (tagEndIndex === -1) {
//     return returnData;
//   }

//   const closeTagLength = getCloseTagLength(tagName);

//   if (closeTagLength === -1) {
//     return returnData;
//   }

//   returnData = removeDataByIndices(returnData, tagEndIndex, tagEndIndex + closeTagLength);

//   return returnData;
// };

// const removeAllTagsFromData = (inputData: string): string => {
//   let returnData = inputData;

//   // // Remove: div
//   while(true) {
//     const processedData = removeTagData(returnData, 'div');
    
//     if (returnData === processedData) {
//       break;
//     }

//     returnData = processedData;
//   }

//   // // Remove: span
//   // while(true) {
//   //   const processedData = removeTagData(returnData, 'span');
    
//   //   if (returnData === processedData) {
//   //     break;
//   //   }

//   //   returnData = processedData;
//   // }
  
//   return returnData;
// };

const unwrapTagData = (inputData: string, tagName: string): string => {
  let returnData = inputData;

  // Try removing the start tag.
  const tagStartIndex = getOpenTagStartIndex(inputData, tagName);

  if (tagStartIndex === -1) {
    return returnData;
  }

  const openTagLength = getOpenTagLength(returnData, tagName, tagStartIndex);

  if (openTagLength === -1) {
    return returnData;
  }

  returnData = removeDataByIndices(returnData, tagStartIndex, tagStartIndex + openTagLength);

  // Try removing a closing tag.
  const tagEndIndex = getCloseTagIndex(returnData, tagName);

  if (tagEndIndex === -1) {
    return returnData;
  }

  const closeTagLength = getCloseTagLength(tagName);

  if (closeTagLength === -1) {
    return returnData;
  }

  returnData = removeDataByIndices(returnData, tagEndIndex, tagEndIndex + closeTagLength);

  return returnData.trim();
};

const unwrapAllTagsData = (inputData: string): string => {
  let returnData = inputData;

  const TAGS_TO_PROCESS = ['div', 'span', 'p', 'a', 'b', 'i', 'em',
    'h1', 'h2', 'h3', 'h4', 'h5', 'code', 'br', 'strong',
    'ul', 'ol', 'li', 'blockquote',
    'img',
  ];

  TAGS_TO_PROCESS.forEach((tagName) => {
    while(true) {
      const processedData = unwrapTagData(returnData, tagName);

      if (returnData === processedData) {
        break;
      }

      returnData = processedData;
    }
  });

  return returnData;
};

const getAttributeData = (inputData: string, attributeName: string): string => {
  const attributeSearchString = `${attributeName}="`;

  const attributeStartIndex = inputData.indexOf(attributeSearchString);

  if (attributeStartIndex === -1) {
    return '';
  }

  const endIndex = inputData.indexOf('"', attributeStartIndex + attributeSearchString.length);

  if (endIndex === -1) {
    return '';
  }

  return inputData.substring(attributeStartIndex + attributeSearchString.length, endIndex);
};

export const getSavedRssEntries = (): RssEntryData[] => {
  const dataToParse = localStorage.getItem(SAVED_ITEMS_NAME) || '';

  let parsedData: any[] = [];

  try {
    parsedData = JSON.parse(dataToParse);
  } catch (e) {
    parsedData = [];
  }

  if (!Array.isArray(parsedData) || parsedData.length === 0) {
    return [];
  }

  const returnData: RssEntryData[] = parsedData.map((parsedEntry): RssEntryData => {
    return {
      title: parsedEntry.title,
      description: parsedEntry.description ?? '',
      imgSrc: parsedEntry.imgSrc ?? '',
      linkUrl: parsedEntry.linkUrl,
      downloadUrl: parsedEntry.downloadUrl,
      date: new Date(parsedEntry.date),
    };
  }).filter((entry) => {
    return entry.title && entry.linkUrl && !Number.isNaN(entry.date.getTime());
  });

  return returnData;
};

export const removeSavedRssEntry = () => {};

export const setSavedRssEntries = (entries: RssEntryData[]): void => {
  const entriesToSave = entries.map((checkEntry) => {
    return {
      ...checkEntry,
      date: checkEntry.date.getTime(),
    };
  });

  const stringData = JSON.stringify(entriesToSave);

  localStorage.setItem(SAVED_ITEMS_NAME, stringData);
};

const isUrlInSavedRssEntries = (linkUrlToFind: string, rssEntries?: RssEntryData[]): boolean => {
  const entriesToSearch = rssEntries ?? getSavedRssEntries();

  const foundEntry = entriesToSearch.find((checkEntry: RssEntryData) => {
    return checkEntry.linkUrl === linkUrlToFind;
  });

  return !!foundEntry;
};

export const addEntryToSavedRssEntries = (entry: RssEntryData, rssEntries?: RssEntryData[]): void => {
  const entriesToProcess = rssEntries ?? getSavedRssEntries();

  if (isUrlInSavedRssEntries(entry.linkUrl, entriesToProcess)) {
    // Url is already in the saved set.
    return;
  }

  entriesToProcess.push(entry);

  setSavedRssEntries(entriesToProcess);
};

export const removeUrlFromSavedRssEntries = (linkUrlToFind: string, rssEntries?: RssEntryData[]): void => {
  const entriesToProcess = rssEntries ?? getSavedRssEntries();

  if (!isUrlInSavedRssEntries(linkUrlToFind, entriesToProcess)) {
    return;
  }

  const entriesToKeep = entriesToProcess.filter((checkEntry: RssEntryData) => {
    return checkEntry.linkUrl !== linkUrlToFind;
  });

  setSavedRssEntries(entriesToKeep);
};

export const getHiddenUrls = (): HiddenUrl[] => {
  const dataToParse = localStorage.getItem(HIDDEN_URLS_NAME) || '';

  let parsedData: any[] = [];

  try {
    parsedData = JSON.parse(dataToParse);
  } catch (e) {
    return [];
  }

  if (!Array.isArray(parsedData)) {
    return [];
  }

  const returnData: HiddenUrl[] = parsedData.map((parsedEntry): HiddenUrl => {
    return {
      url: parsedEntry.url,
      time: parseInt(parsedEntry.time, 10),
    };
  }).filter((entry) => {
    return entry.url && !Number.isNaN(entry.time);
  });

  return returnData;
};

export const hideRssLinkUrl = (linkUrl: string) => {
  const hiddenUrls = getHiddenUrls();

  if (!hiddenUrls.some((hiddenEntry) => hiddenEntry.url === linkUrl)) {
    hiddenUrls.push({ url: linkUrl, time: Date.now() });
  }

  // 32KB.
  const DATA_VALUE_MAX = 1024 * 32;

  let hiddenUrlsToSave = hiddenUrls;
  let infinityGuard = 10;

  let stringifiedData = JSON.stringify(hiddenUrlsToSave);

  while (infinityGuard--
    && stringifiedData.length > DATA_VALUE_MAX) {
    hiddenUrlsToSave = removeOldestHiddenRssEntry(hiddenUrlsToSave, 4);
    stringifiedData = JSON.stringify(hiddenUrlsToSave)
  }

  localStorage.setItem(HIDDEN_URLS_NAME, stringifiedData);
};

export const clearHiddenRssStorage = () => {
  localStorage.setItem(HIDDEN_URLS_NAME, '');
};

export const filterRssEntriesAgainstHiddenUrls = (rssEntries: RssEntryData[]) => {
  const hiddenUrls = getHiddenUrls();

  return rssEntries.filter((entry) => !hiddenUrls.some((hiddenEntry) => {
    return hiddenEntry.url === entry.linkUrl;
  }));
};

const removeOldestHiddenRssEntry = (hiddenUrls: HiddenUrl[],
  numToRemove: number = 1): HiddenUrl[] => {
  // Sort from oldest to youngest.
  const sortedHiddenUrls = hiddenUrls.sort((objA, objB) => {
    return objA.time - objB.time;
  });

  if (sortedHiddenUrls.length <= 1) {
    return sortedHiddenUrls;
  }

  if (sortedHiddenUrls.length > 0) {
    // Remove the first (oldest) entry.
    for (let i = 0; i < numToRemove && sortedHiddenUrls.length > 1; ++i) {
      return sortedHiddenUrls.slice(1, sortedHiddenUrls.length);
    }
  }

  return sortedHiddenUrls;
};

const getPublishedDateFromItemData = (itemData: string): Date => {
  let publishedDateData = getDataBetweenTags(itemData, 'pubDate');
  publishedDateData = publishedDateData || getDataBetweenTags(itemData, 'published');

  return publishedDateData ? new Date(publishedDateData) : new Date();
};

const getLinkUrlFromItemData = (itemData: string, rssSource: RssSource): string | null => {
  let linkUrl = getDataBetweenTags(itemData, 'link');

  if (!linkUrl) {
    const linkData = getTagData(itemData, 'link');
    if (linkData) {
      linkUrl = getAttributeData(linkData, 'href');
    }
  }

  if (!linkUrl) {
    const linkData = getTagData(itemData, 'enclosure');
    if (linkData) {
      linkUrl = getAttributeData(linkData, 'url');
    }
  }

  // Skip any opinion, sports, Spanish version, etc.
  if (rssSource.url.indexOf('.nytimes.com') !== -1
    && (linkUrl.indexOf('/opinion/') !== -1
    || linkUrl.indexOf('/sports/') !== -1
    || linkUrl.indexOf('/briefing/') !== -1
    || linkUrl.indexOf('/style/') !== -1
    || linkUrl.indexOf('/espanol/') !== -1
    || linkUrl.indexOf('/zh-hans/') !== -1
    || linkUrl.indexOf('/yi/') !== -1
    || linkUrl.indexOf('/books/review/') !== -1
    || linkUrl.indexOf('/dining/') !== -1
    || linkUrl.indexOf('/crosswords/') !== -1)) {
    return null;
  }

  if (rssSource.url.indexOf('.npr.org') !== -1
    && (linkUrl.indexOf('/up-first-briefing-') !== -1)) {
    return null;
  }

  if (rssSource.url.indexOf('.theonion.com') !== -1
    && (linkUrl.indexOf('/the-onion-5-') !== -1)) {
    return null;
  }

  if (rssSource.url.indexOf('.abc15.com') !== -1
    && (linkUrl.indexOf('/now-hiring/') !== -1
    || linkUrl.indexOf('/rainfall-totals-/') !== -1
    || linkUrl.indexOf('/sports/') !== -1)) {
    return null;
  }

  return linkUrl;
};

const getTitleFromItemData = (itemData: string, rssSource: RssSource): string => {
  // Title.
  let title = getDataBetweenTags(itemData, 'title') || '';
  title = cleanupDisplayString(title);

  // Title prefix.
  let titlePrefix = '';

  if (rssSource.displayTitlePrefix) {
    titlePrefix = `${rssSource.displayTitlePrefix} - `;
  }

  return `${titlePrefix}${title}`;
};

const getImgSrcFromItemData = (itemData: string, inputData: string, rawDescriptionData: string) => {
  let imgSrc = '';
  if (!imgSrc) {
    const mediaContentData = getTagData(itemData, 'media:thumbnail');
    if (mediaContentData) {
      imgSrc = getAttributeData(mediaContentData, 'url');
    }
  }

  if (!imgSrc) {
    // More than one "media:conent" can be present.
    // Search for the first image one.
    const mediaContentTagName = 'media:content';
    let runningOffset = 0;

    while (!imgSrc) {
      const tagStartIndex = getOpenTagStartIndex(itemData, mediaContentTagName, runningOffset);
      if (tagStartIndex === -1) {
        break;
      }

      const mediaContentData = getTagData(itemData, mediaContentTagName, tagStartIndex);
      if (!mediaContentData) {
        break;
      }

      let isImageMediaContent = true;

      if (isImageMediaContent) {
        // Ensure this media:content is an image type.
        // type="audio/mpeg" medium="audio"
        const mediumValue = getAttributeData(mediaContentData, 'medium');
        if (mediumValue && mediumValue !== 'image') {
          isImageMediaContent = false;
        }
      }

      if (isImageMediaContent) {
        // Ensure this media:content is an image type.
        // type="audio/mpeg" medium="audio"
        const typeValue = getAttributeData(mediaContentData, 'type');
        if (typeValue && typeValue.indexOf('image') !== 0) {
          isImageMediaContent = false;
        }
      }

      if (isImageMediaContent) {
        imgSrc = getAttributeData(mediaContentData, 'url');
      }

      // If the tag wasn't found then search a bit further.
      runningOffset = tagStartIndex + 1;
    }
  }

  if (!imgSrc) {
    const enclosureData = getTagData(itemData, 'enclosure');
    if (enclosureData) {
      imgSrc = getAttributeData(enclosureData, 'url');

      if (imgSrc
        && getAttributeData(enclosureData, 'type') === 'audio/mpeg') {
        imgSrc = '';
      }
    }
  }

  if (!imgSrc) {
    // Check if there is an image right at the front of the Description data.
    let tempDescriptionData = rawDescriptionData;
    if (tempDescriptionData && tempDescriptionData.indexOf('<![CDATA[<img') === 0) {
      tempDescriptionData = tempDescriptionData.split('<![CDATA[')[1];

      tempDescriptionData = getTagData(tempDescriptionData, 'img');

      if (tempDescriptionData) {
        imgSrc = getAttributeData(tempDescriptionData, 'src');
      }
    }
  }

  return imgSrc;
};

export const processRssResponse = (inputData: string, rssSource: RssSource): RssEntryData[] => {
  // Normal data has a "channel" that contains "item" children.
  // Youtube uses a "feed" containing "entry" children.
  let isFeedType = false;

  let channelData = getTagData(inputData, 'channel');

  if (!channelData) {
    channelData = getTagData(inputData, 'feed');
    isFeedType = !!channelData;
  }

  if (!channelData) {
    return [];
  }

  const itemTagName = isFeedType ? 'entry' : 'item';

  const itemTagStartIndex = getOpenTagStartIndex(channelData, itemTagName);
  if (itemTagStartIndex === -1) {
    return [];
  }

  let dataToProcess = inputData;
  const processedRssEntries = [];
  let continueProcessingItems = true;

  while (continueProcessingItems) {
    let itemData = getTagData(dataToProcess, itemTagName, itemTagStartIndex);

    if (!itemData) {
      continueProcessingItems = false;
      break;
    }

    const dataAfterRemoval = removeDataByIndexAndLength(dataToProcess, itemTagStartIndex, itemData.length);
    if (!dataAfterRemoval) {
      continueProcessingItems = false;
      break;
    }

    dataToProcess = dataAfterRemoval;

    // Published date.
    const publishedDate = getPublishedDateFromItemData(itemData);
    const TWO_WEEKS_MS = 1000 * 60 * 60 * 24 * 7 * 2;

    if (publishedDate) {
      const timeLimitMs = rssSource.historyLimitMs ?? TWO_WEEKS_MS;
      
      if (publishedDate.getTime() < (Date.now() - timeLimitMs)) {
        continue;
      }
    }

    // Link to item.
    const linkUrl = getLinkUrlFromItemData(itemData, rssSource);
    if (!linkUrl) {
      continue;
    }

    // Title.
    const title = getTitleFromItemData(itemData, rssSource);
    const titleLowerCase = title.toLowerCase();

    // The Onion's articles about "Things to never say to" are dumb. Remove them.
    if (rssSource.url.indexOf('.theonion.com') !== -1
      && (titleLowerCase.indexOf('things to ') !== -1
      || titleLowerCase.indexOf('did you know?') !== -1
      || titleLowerCase.indexOf('exclusive interview with ') !== -1
      || titleLowerCase.indexOf(' could you pass ') !== -1
      || titleLowerCase.indexOf(' explain why ') !== -1
      // "This Week In Local New" / "This Week In Breaking News".
      || titleLowerCase.indexOf('this week in ') !== -1
      || titleLowerCase.indexOf('this week\'s most viral') !== -1
      || titleLowerCase.indexOf(' what to know about ') !== -1)) {
      continue;
    }

    // Remove NPR Opinion articles.
    if (rssSource.url.indexOf('.npr.org') !== -1
      && (titleLowerCase.indexOf('opinion: ') !== -1)) {
      continue;
    }

    // Remove ABC15 Rainfall articles.
    if (rssSource.url.indexOf('.abc15.com') !== -1
      && (titleLowerCase.indexOf('what to expect:') !== -1)) {
      continue;
    }

    // Remove The Daily Show's youtube shorts.
    if (rssSource.url.indexOf('.youtube.com') !== -1
      && titleLowerCase.indexOf('daily show') !== -1
      && titleLowerCase.indexOf('#shorts') !== -1) {
      continue;
    }

    // Remove The Daily Show's youtube throwbacks.
    if (rssSource.url.indexOf('.youtube.com') !== -1
      && titleLowerCase.indexOf('daily show') !== -1
      && titleLowerCase.indexOf('#tdsthrowback') !== -1) {
      continue;
    }

    // Remove Powerball articles.
    if (titleLowerCase.indexOf('powerball') !== -1) {
      continue;
    }

    // Description.
    let rawDescriptionData = '';
    rawDescriptionData = rawDescriptionData || getDataBetweenTags(itemData, 'description');
    rawDescriptionData = rawDescriptionData || getDataBetweenTags(itemData, 'media:description');
    rawDescriptionData = rawDescriptionData || '';

    let descriptionData = cleanupDisplayString(rawDescriptionData);
    descriptionData = unwrapAllTagsData(descriptionData);

    // Image.
    let imgSrc = getImgSrcFromItemData(itemData, inputData, rawDescriptionData);

    // Download url.
    let downloadUrl = undefined;

    const linkUrlTokens = linkUrl.split('?');
    let potentialDownloadUrl = linkUrlTokens[0];
    if (potentialDownloadUrl &&
      (potentialDownloadUrl.endsWith('.mp3') || potentialDownloadUrl.endsWith('.mp4') || potentialDownloadUrl.endsWith('.webm'))) {
      downloadUrl = potentialDownloadUrl;
    }

    if (!downloadUrl) {
      const linkData = getTagData(itemData, 'enclosure');
      if (linkData) {
        const enclosureUrl = getAttributeData(linkData, 'url');

        if (enclosureUrl) {
          const linkUrlTokens = enclosureUrl.split('?');
          potentialDownloadUrl = linkUrlTokens[0];

          if (potentialDownloadUrl &&
            (potentialDownloadUrl.endsWith('.mp3') || potentialDownloadUrl.endsWith('.mp4') || potentialDownloadUrl.endsWith('.webm'))) {
            downloadUrl = potentialDownloadUrl;
          }
        }
      }
    }

    const newRssEntry: RssEntryData = {
      title,
      description: descriptionData,
      imgSrc,
      linkUrl,
      downloadUrl,
      date: publishedDate || new Date(),
    };
    processedRssEntries.push(newRssEntry);
  }

  return processedRssEntries;
};

export const dismissRssDescriptionMessage = (): void => {
  localStorage.setItem(RSS_MESSAGE_DISMISSED_NAME, 'true');
};

export const isRssDescriptionMessageDismissed = (): boolean => {
  const dataToParse = localStorage.getItem(RSS_MESSAGE_DISMISSED_NAME) || '';

  return !!dataToParse;
};
