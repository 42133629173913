import { FunctionComponent } from 'react';

type PropsType = {
  className?: string;
  fillColor?: string;
  size?: number;
  width?: number;
  height?: number;
};

export const IconDownload: FunctionComponent<PropsType> = ({
  className,
  fillColor,
  size,
  width,
  height,
}) => {
  const widthToUse = width ?? size ?? 200;
  const heightToUse = height ?? size ?? 200;
  const fillColorToUse = fillColor ?? '#000000';

  // Icon from: https://publicicons.org/download-icon
  return (
    <svg
      className={className}
      fill={fillColorToUse}
      width={widthToUse}
      height={heightToUse}
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="48.732,69.783 91.039,27.476 102.778,39.215 60.472,81.527 "/>
      <rect x="50.999" y="3.424" width="19.055" height="60.21"/>
      <polygon points="60.543,81.572 18.22,39.283 29.941,27.542 72.271,69.85 "/>
      <rect x="9" y="99.575" width="103" height="17"/>
      <rect x="5.5" y="68.576" width="17" height="48"/>
      <rect x="97.5" y="68.576" width="17" height="48"/>
    </svg>
  );
};
