import { FunctionComponent } from 'react';

export const JGFooter: FunctionComponent = () => {
  const year = (new Date()).getFullYear();

  return (
    <div className="copyright">
      <span className="span-wrapper">
        &copy; { year }&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Joe Gosselin
      </span>
    </div>
  );
};