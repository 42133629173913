import { FunctionComponent } from 'react';

type PropsType = {
  className?: string;
  fillColor?: string;
  size?: number;
  width?: number;
  height?: number;
};

export const IconFloppy: FunctionComponent<PropsType> = ({
  className,
  fillColor,
  size,
  width,
  height,
}) => {
  const widthToUse = width ?? size ?? 200;
  const heightToUse = height ?? size ?? 200;
  const fillColorToUse = fillColor ?? '#000000';

  return (
    <svg
      className={className}
      fill={fillColorToUse}
      width={widthToUse}
      height={heightToUse}
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.014 3.638v11.133a.476.476 0 0 1-.475.475H3.214a1.342 1.342 0 0 1-.813-.333L1.325 13.86a1.301 1.301 0 0 1-.34-.808V3.638a.476.476 0 0 1 .476-.475H12.54a.476.476 0 0 1 .474.475zm-2.208.954a.476.476 0 0 0-.475-.475H3.67a.476.476 0 0 0-.475.475v3.212a.476.476 0 0 0 .475.475h6.66a.476.476 0 0 0 .476-.475zm-.5 5.883a.477.477 0 0 0-.475-.476H4.16a.477.477 0 0 0-.475.476v3.442a.476.476 0 0 0 .475.475h5.67a.476.476 0 0 0 .475-.475zm-4.356.635H4.74v2.2h1.21z"
      />
    </svg>
  );
};
