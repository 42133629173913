import { FunctionComponent, useCallback } from 'react';
import { useMatch } from "react-router-dom";
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import jgLogoImage from './jg-logo.png';
import { RESUME_URL_TOKEN } from '../resume/constants';
import { BLOG_URL_TOKEN } from '../blog/constants';
import { RSS_URL_TOKEN } from '../works/rss/constants';

export const JGHeader: FunctionComponent = () => {
  let match = useMatch("/");
  const matchBlog = useMatch(`/${BLOG_URL_TOKEN}/*`);
  const matchResume = useMatch(`/${RESUME_URL_TOKEN}/*`);
  const matchRss = useMatch(`/${RSS_URL_TOKEN}/*`);

  const isAtHome = useCallback(() => {
    return match && match.pathname === '/';
  }, [match]);

  const isAtBlog = useCallback(() => {
    return matchBlog && matchBlog.pathnameBase === `/${BLOG_URL_TOKEN}`;
  }, [matchBlog]);

  const isAtResume = useCallback(() => {
    return matchResume && matchResume.pathnameBase === `/${RESUME_URL_TOKEN}`;
  }, [matchResume]);

  const isAtRss = useCallback(() => {
    return matchRss && matchRss.pathnameBase === `/${RSS_URL_TOKEN}`;
  }, [matchRss]);

  return (
    <div className="nav-bar">
      <div className="nav-buttons-container nav-bButtons-container-left">
        <Link
          className="jg-logo-link"
          to="/"
        >
          <img
            className="jg-logo"
            src={jgLogoImage}
            alt="JG logo"
          />
        </Link>
        <div className="nav-button-container">
          <Link
            className={classnames('nav-bar-link', {
              'is-active': isAtHome(),
            })}
            to="/"
          >
            HOME
          </Link>
        </div>

        <div className="nav-button-container">
          <Link
            className={classnames('nav-bar-link', {
              'is-active': isAtBlog(),
            })}
            to={`/${BLOG_URL_TOKEN}`}
          >
            BLOG
          </Link>
        </div>

        <div className="nav-button-container">
          <Link
            className={classnames('nav-bar-link', {
              'is-active': isAtResume(),
            })}
            to={`/${RESUME_URL_TOKEN}`}
          >
            RÉSUMÉ
          </Link>
        </div>

        <div className="nav-button-container">
          <Link
            className={classnames('nav-bar-link', {
              'is-active': isAtRss(),
            })}
            to={`/${RSS_URL_TOKEN}`}
          >
            RSS
          </Link>
        </div>
      </div>

      <div className="nav-buttons-container nav-buttons-container-right">
        <a
          className="nav-bar-professional-network-link linkedIn-link"
          href="https://www.linkedin.com/in/joegosselin"
          title="LinkedIn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns-svg="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
              fill="#FFFFFF"
              d="M22.2,0H1.8C0.8,0,0,0.8,0,1.7v20.5c0,1,0.8,1.7,1.8,1.7h20.5c1,0,1.8-0.8,1.8-1.7V1.7
              C24,0.8,23.2,0,22.2,0z M7.1,20.5H3.6V9h3.6V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1
              C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0
              c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z"
            />
          </svg>
        </a>

        <a
          className="nav-bar-professional-network-link hacker-rank-link"
          href="https://www.hackerrank.com/cujoe0"
          title="HackerRank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns-svg="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
                fill="#FFFFFF"
                d="M22.9,10.8L22.9,10.8c0-2.2-0.2-4.3-0.5-4.8c-0.2-0.4-1.5-1.3-3-2.3l-4.1-2.4C13.8,0.6,12.5,0,12,0
                c-0.6,0-2.4,0.9-4.4,2C6.1,2.8,4.5,3.7,3.4,4.5C2.5,5.2,1.8,5.7,1.6,6C1.5,6.3,1.3,7.2,1.2,8.3l0,0c-0.1,1.5-0.1,3.3-0.1,5.1
                c0.1,2.2,0.2,4.1,0.5,4.6c0.7,1.2,9,6,10.4,6c0.6,0,2.3-0.8,4.2-1.9c1.5-0.8,3.1-1.8,4.3-2.6l0,0c0.9-0.6,1.6-1.2,1.8-1.5
                s0.3-1.2,0.4-2.3l0,0C22.9,14.3,22.9,12.5,22.9,10.8z M16.9,17.1l-1.7,1.5c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.6-1.5
                c-0.1-0.1-0.2-0.2-0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1h0.4v-3.4h-3.6v3.7c0,0.1-0.1,0.3-0.3,0.3H8c-0.1,0-0.3-0.1-0.3-0.3l0,0V7.4H7.2
                C7.1,7.4,7,7.3,7,7.1C7,7.1,7,7,7.1,6.9l1.7-1.5C8.8,5.3,8.9,5.3,9,5.3c0.1,0,0.2,0,0.2,0.1l1.6,1.5c0.1,0.1,0.2,0.2,0.1,0.3
                c-0.1,0.1-0.1,0.1-0.2,0.1h-0.4v3.3h3.6V7c0-0.1,0.1-0.3,0.3-0.3h2c0.1,0,0.3,0.1,0.3,0.3l0,0v9.5h0.5c0.1,0,0.3,0.1,0.3,0.3
                C17,16.9,17,17,16.9,17.1z"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
