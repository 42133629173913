import { FunctionComponent } from 'react';

type PropsType = {
  className?: string;
  fillColor?: string;
  pathColor?: string;
  size?: number;
  width?: number;
  height?: number;
};

// Icon from: https://publicicons.org/external-icon
export const IconExternal: FunctionComponent<PropsType> = ({
  className,
  fillColor = 'none',
  pathColor = '#020202',
  size,
  width,
  height,
}) => {
  const widthToUse = width ?? size ?? 200;
  const heightToUse = height ?? size ?? 200;

  return (
    <svg
      className={className}
      fill={fillColor}
      width={widthToUse}
      height={heightToUse}
      x="0px"
      y="0px"
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <polygon fill={pathColor} points="120.088,16.696 60.256,16.697 60.257,0.095 120.092,0.091 "/>
      <rect x="55.91" y="24.562" transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.0877 70.8061)" fill={pathColor} width="60.209" height="19.056"/>
      <polygon fill={pathColor} points="119.975,0.107 119.996,59.938 103.408,59.95 103.393,0.104 "/>
      <rect x="3" y="23.5" fill={pathColor} width="17" height="87"/>
      <rect x="86.49" y="76.059" fill={pathColor} width="17" height="36.941"/>
      <rect x="3" y="16.692" fill={pathColor} width="40.655" height="17"/>
      <rect x="3" y="96" fill={pathColor} width="100.49" height="17"/>
    </svg>
  );
};
