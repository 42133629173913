import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import stuntTrackImage from './stunt-track-1-web.png';
import billboardRidersImage from './billboard-riders-0-web.png';
import atvOffroadTrailerVideo from './2XL-ATV-Offroad-HD-Trailer.mp4';
import atvOffroadTrailerVideoPoster from './2XL-ATV-Offroad-HD-Trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Joined the project late in the development cycle.',
  'Helped test and diagnose bugs.',
  'Implemented UI controls and audio cues.',
  'Added special stunt competition within the the freestyle tracks.',
];

export const WorksAtvOffroad: FunctionComponent = () => {
  useEffect(() => {
    document.title = "2XL ATV Offroad | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL ATV Offroad"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2009',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.appleAppStore}
                url="https://web.archive.org/web/20191016143805/https://apps.apple.com/us/app/2xl-atv-offroad/id330865053"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/20181127164057/http://2xlgames.com/atv/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=R_1cMV9nb68
          src={atvOffroadTrailerVideo}
          caption="2XL ATV Offroad HD trailer"
          poster={atvOffroadTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={stuntTrackImage}
          caption="Large, open spaces are a great place for a stunt contest."
        />
      </Container>

      <Container centerText>
        <Image
          src={billboardRidersImage}
          caption="The 3D models turn into 2D billboards when they are far from the camera. This helped to keep the game performing well even on older hardware."
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            TouchArcade - "‘2XL ATV Offroad’ – A Console Quality iPhone Racer" - November 1, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://toucharcade.com/2009/10/17/2xl-atv-offroad-a-console-quality-iphone-racer/"
            />
          </li>

          <li>
            IGN - "2XL ATV Offroad Review" - October 23, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://www.ign.com/articles/2009/10/23/2xl-atv-offroad-review"
            />
          </li>

          <li>
            Racer X - "2XL Games Releases 2XL ATV Offroad for iPhone and iPod" - October 14, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://racerxonline.com/2009/10/14/2xl-games-releases-2xl-atv-offroad-for-iphone-and-ipod"
            />
          </li>

          <li>
            VideoGamer - "2XL ATV Offroad Review" - June 6, 2021&nbsp;
            <WebLink
              linkText="link"
              url="https://www.videogamer.com/reviews/2xl-atv-offroad-review/"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
