import { FunctionComponent, ReactNode } from 'react';

type PropsType = {
  header: string | ReactNode;
};

export const FigHeader: FunctionComponent<PropsType> = ({
  header,
}) => {
  return (
    <div className="fig-header">
      {header}
    </div>
  );
};
