import { CSSProperties, FunctionComponent, useCallback } from 'react';

import googlePlayBadge from '../../images/google-play-badge.png';
import isoAppStoreBadge from '../../images/ios-appstore-badge.png';
import psnStoreBadge from '../../images/ps3-psn-logo.png';
import xboxStoreBadge from '../../images/xbox-360-logo.png';

export enum AppType {
  appleAppStore = 'appleAppStore',
  googlePlay = 'googlePlay',
  playStationNetwork = 'playStationNetwork',
  xboxOnline = 'xboxOnline',
  site = 'site',
};

type PropsType = {
  appType: AppType;
  url: string;
  style?: CSSProperties;
  siteText?: string;
};

export const AppLink: FunctionComponent<PropsType> = ({
  appType,
  url,
  style: styleProp,
  siteText,
}) => {
  const getAppImage = useCallback(() => {
    switch (appType) {
      case AppType.googlePlay:
        return (
          <img
            className="app-image"
            style={styleProp}
            src={googlePlayBadge}
            alt="Google Play Store"
          />
        );

      case AppType.appleAppStore:
        return (
          <img
            className="app-image"
            style={styleProp}
            src={isoAppStoreBadge}
            alt="Apple App Store"
          />
        );

      case AppType.playStationNetwork:
        return (
          <img
            className="app-image"
            style={styleProp}
            src={psnStoreBadge}
            alt="PlayStation Network"
          />
        );

      case AppType.xboxOnline:
        return (
          <img
            className="app-image"
            style={styleProp}
            src={xboxStoreBadge}
            alt="Xbox Store"
          />
        );

      case AppType.site:
        return (
          <div
            className="site-box app-image"
            style={styleProp}
          >
            <span className="site-box-text">
              {siteText || 'Site'}
            </span>
          </div>
        );

      default:
        return null;
    }
  }, [appType, siteText, styleProp]);

  return (
    <a
      className="app-link"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {getAppImage()}
    </a>
  );
};
