import { FunctionComponent } from 'react';
import { PageHeaderImage } from '../../pieces/page-header-image';

type PropsType = {
  iconPath?: string;
  title: string;
  description: string;
  dateString: string;
};

export const BlogEntryHeader: FunctionComponent<PropsType> = ({
  iconPath,
  title,
  description,
  dateString,
}) => {
  return (
    <div className="blog-entry-header">
      <PageHeaderImage
        iconPath={iconPath}
        header={title}
      />

      <div>
        <div className="title">
          {title}
        </div>

        <span className="description">
          {description}
        </span>

        <span className="date">
          {dateString}
        </span>
      </div>
    </div>
  );
};
