import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { WorkHeader } from '../../pieces/work-header';
import { Image } from '../../pieces/image';
import { Video } from '../../pieces/video';

import headerIcon from './icon.jpeg';
import chartBuilderImage from './chart-builder-5-web.png';
import chartBuilderSlaMetricsImage from './sla-metrics-web.png';
import serverPowerUtilizationImage from './server-power-utilization-web.png';
import assetManagementImage from './asset-management-2-web.png';
import gartnerImage from './gartner1.jpg';

import birthOfAModuleVideo from './BASELAYER-Birth-of-a-Module.mp4';
import birthOfAModuleVideoPoster from './BASELAYER-Birth-of-a-Module-poster.png';
import edgeDataStationVideo from './BASELAYER-EDGE-SRP-DataStation-Animation.mp4';
import edgeDataStationVideoPoster from './BASELAYER-EDGE-SRP-DataStation-Animation-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Created an embeddable graphing utility capable of handling over 100K data points. Highly praised by customers.',
  'Dynamically generated floor plans and modules with programmatic heat maps and search capabilities.',
  'Contributed to versions 3.5, 3.7, and 4.0 of our DCIM product.',
  'Conducted code reviews with junior developers to identify performance and scalability improvements.',
];

export const WorksBaselayer: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Baselayer | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Baselayer Technology, LLC"
          position="Lead Content Software Engineer"
          location="Chandler, AZ"
          details={[
            'August 2015 – February 2018',
            <WebLink
              linkText="Baselayer"
              url="https://www.baselayer.com/"
            />
          ]}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Image
          src={chartBuilderImage}
          header="Chart Builder"
          caption="I created the Chart Builder and I'm proud to see the extent to which it was used. It could be embedded within any screen and could handle thousands of data points."
        />
      </Container>

      <Container centerText>
        <Image
          src={chartBuilderSlaMetricsImage}
          header="Embedded Chart Builder"
          caption="The Chart Builder was easy to customize. Here it is being embedded within the SLA Metrics screen."
        />
      </Container>

      <Container centerText>
        <Image
          src={serverPowerUtilizationImage}
          header="Graphing sensor data"
          caption="The majority of cases involved graphing data involved with a sensor. The Chart Builder could also receive a set of data points and graphs those in a variety of ways."
        />
      </Container>

      <Container centerText>
        <Image
          src={assetManagementImage}
          header="Asset Management - Floor Plans"
          caption="When I was asked to create the Asset Management screen, it started out small with some basic visualization and search options. It evolved to support programmatic heat maps, rack elevations, and the ability to move devices within your data center."
        />
      </Container>

      <Container centerText>
        <Image
          src={gartnerImage}
          caption="The Global Dashboard on display at a Gartner event."
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=PrGLgH0eWjY
          src={birthOfAModuleVideo}
          caption="Modules being built at our Chandler building. I worked on the second floor and could see the modules being assembled."
          poster={birthOfAModuleVideoPoster}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=PZdhC2LQIe4
          src={edgeDataStationVideo}
          caption="BASELAYER / SRP Animation"
          poster={edgeDataStationVideoPoster}
        />
      </Container>

      <Container centerText>
        <iframe
          title="VR Modular Data Center Tour"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/QINOFn1EUOI"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          // allowfullscreen
        />
      </Container>
    </div>
  );
};
