import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import mainMenu0Image from './main-menu-0-web.png';
import mainMenu1Image from './main-menu-1-web.png';
import mainMenu2Image from './main-menu-2-web.png';
import carrierExplodingImage from './carrier-exploding-web.png';
import phalanxFiringImage from './phalanx-firing-web.png';

import fleetDefenseTrailerVideo from './Fleet-Defense-Trailer.mp4';
import fleetDefenseTrailerVideoPoster from './Fleet-Defense-Trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Implemented UI flow.',
  'Achievement system updates.',
  'Setup audio triggers for button-press feedback.',
];

const animMainMenuImages = [mainMenu0Image, mainMenu1Image, mainMenu2Image];

export const WorksFleetDefense: FunctionComponent = () => {
  const [mainMenuImgFrame, setMainMenuImgFrame] = useState(animMainMenuImages[0]);

  const advanceMainMenuAnim = useCallback(() => {
    let currentIndex = 0;

    for (let i = 0; i < animMainMenuImages.length; ++i) {
      if (animMainMenuImages[i] === mainMenuImgFrame) {
        currentIndex = i;
        break;
      }
    }

    const newIndex = (currentIndex === -1)
      ? 0
      : (currentIndex + 1) % animMainMenuImages.length;

    setMainMenuImgFrame(animMainMenuImages[newIndex]);
  }, [mainMenuImgFrame]);

  useEffect(() => {
    document.title = "2XL Fleet Defense | Joe Gosselin";

    const intervalHandle = window.setInterval(advanceMainMenuAnim, 200);

    return () => {
      window.clearInterval(intervalHandle);
    };
  });

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL Fleet Defense"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2009',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.appleAppStore}
                url="https://web.archive.org/web/20210416043411/https://apps.apple.com/us/app/2xl-fleet-defense/id338029946"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/20180829125154/http://2xlgames.com/fleetdefense"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=wd33tphehvc
          src={fleetDefenseTrailerVideo}
          caption="Launch trailer"
          poster={fleetDefenseTrailerVideoPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={mainMenuImgFrame}
          header="As a test, I spent some extra time one weekend putting slight rotation and translations on the Main Menu's buttons. Management liked it so we kept it."
          caption="Main Menu"
        />
      </Container>

      <Container centerText>
        <Image
          header="Gameplay"
          src={carrierExplodingImage}
        />
      </Container>

      <Container centerText>
        <Image
          src={phalanxFiringImage}
          header="Over time, the Phalanx meter fills. This attack clears the screen of all missiles and enemies. It refills slowly so use it wisely."
          caption="Gameplay - Phalanx"
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            TouchArcade - "‘2XL Fleet Defense’: War on the Open Seas" - December 23, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://toucharcade.com/2009/12/05/2xl-fleet-defense-war-on-the-open-seas/"
            />
          </li>

          <li>
            IGN - "2XL Fleet Defense Touches Down on the USS iPhone" - December 7, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://www.ign.com/articles/2009/12/07/2xl-fleet-defense-touches-down-on-the-uss-iphone"
            />
          </li>

          <li>
            iLounge - "iPhone Gems: 2XL Fleet Defense, Glyder 2 + Jungle Style Pinball" - May 15, 2021&nbsp;
            <WebLink
              linkText="link"
              url="https://www.ilounge.com/index.php/articles/comments/iphone-gems-2xl-fleet-defense-glyder-2-jungle-style-pinball"
            />
          </li>

          <li>
            AppSpy - "2XL Fleet Defense Review" - December 11, 2009&nbsp;
            <WebLink
              linkText="link"
              url="https://www.appspy.com/review/3683/2xl-fleet-defense"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
