import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import raceGameplayImage from './race-3-web.png';
import worldMapImage from './track-select-0-web.png';
import trophyLiteRallyVideo from './TrophyLite-Rally-from-2XL-Games.mp4';
import trophyLiteRallyVideoPoster from './TrophyLite-Rally-from-2XL-Games-poster.png';

const contributions = [
  'Implemented UI flow.',
  'Setup audio triggers for button-press feedback.',
  'Implemented lap settings and HUD UI for waypoint tracking.',
  'Helped implement system that assisted in the creation of the single player career.',
];

export const WorksTrophyLite: FunctionComponent = () => {
  useEffect(() => {
    document.title = "2XL TROPHYLITE Rally | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="2XL TROPHYLITE Rally"
          details={[
            'Developer: 2XL Games, Inc.',
            'Released: 2010',
            'Platforms: iOS',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.appleAppStore}
                url="https://itunes.apple.com/us/app/2xl-trophylite-rally-hd/id374938403?mt=8"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Image
          src={raceGameplayImage}
          header="The game involved racing on circuit tracks, rally racing on an A-to-B track, and racing a specific set of segments from the rally A-to-B track."
          caption="Gameplay"
        />
      </Container>

      <Container centerText>
        <Image
          src={worldMapImage}
          header="I implemented the world map. The zooming and panning uses easings that I wrote."
          caption="Map screen"
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=HQ3VCFgrPO0
          src={trophyLiteRallyVideo}
          caption="TrophyLite Rally from 2XL Games"
          poster={trophyLiteRallyVideoPoster}
        />
      </Container>
    </div>
  );
};
