import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { BlogListEntry } from '../blog-list-entry';

import localizationImage from '../2014-08-29-localization/icon.png';
import arabicImage from '../2018-02-27-arabic/icon.png';
import jpegDecoderImage from '../2018-03-27-jpeg-decoder/icon.png';

export const BlogList: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Blog | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <BlogListEntry
          title="JPEG Decoder"
          description="Implementing my own JPEG decoder."
          imgSrc={jpegDecoderImage}
          linkUrl="./2018-03-27-jpeg-decoder"
          dateString="3/27/2018"
        />
      </Container>

      <Container>
        <BlogListEntry
          title="Rendering Arabic Text"
          description="Updating a game engine to support rendering text for right-to-left languages like Arabic."
          imgSrc={arabicImage}
          linkUrl="./2018-02-27-arabic"
          dateString="2/27/2018"
        />
      </Container>

      <Container>
        <BlogListEntry
          title="Localization"
          description="Localization is a great way to expand your audience. Here are some strategies I use to localize text and images."
          imgSrc={localizationImage}
          linkUrl="./2014-08-29-localization"
          dateString="8/29/2014"
        />
      </Container>
    </div>
  );
};
