
export enum WorksUrlTokens {
  ROOT = 'works',
  gpsi = 'gpsi',
  keap = 'keap',
  baselayer = 'baselayer',
  generalMotors = 'general-motors',
  twoxlGames = '2xl-games',
  spelltorn =  'spelltorn',
  street = 'street',
  capitalCity = 'capital-city',
  mcgrathsOffroad = 'mcgraths-offroad',
  mxOffroad = 'mx-offroad',
  mxMatchup = 'mx-matchup',
  tapper = 'tapper',
  hyundaiTimeSquare = 'hyundai-time-square',
  trophylite = 'trophylite',
  snocross = 'snocross',
  hyundaiVeloster = 'hyundai-veloster',
  fleetDefense = 'fleet-defense',
  atvOffroad = 'atv-offroad',
  wavesOfDestruction = 'waves-of-destruction',
  squareDestroyer = 'square-destroyer',
  talon = 'talon',
};
