import { initializeApp } from 'firebase/app';
import 'firebase/compat/functions';
import { FIREBASE_PROJECT_ID } from './constants';

let firebaseApp = null;

export const loadFirebase = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    firebaseApp = initializeApp({});
    // let app = getApp();

    /*
    let features = [
      // 'auth', 
      // 'database', 
      // 'firestore',
      'functions',
      // 'messaging', 
      // 'storage', 
      // 'analytics', 
      // 'remoteConfig',
      // 'performance',
    ].filter(feature => typeof app[feature] === 'function');
    // loadEl.textContent = `Firebase SDK loaded with ${features.join(', ')}`;

    if (process.env.NODE_ENV === 'development') {
      console.log(`Firebase SDK loaded with ${features.join(', ')}`);
    }
    // */
  } catch (e) {
    console.error(e);
    // loadEl.textContent = 'Error loading the Firebase SDK, check the console.';

    if (process.env.NODE_ENV === 'development') {
      console.log('Error loading the Firebase SDK, check the console.');
    }
  }
};

export const getCloudFunctionUrl = (funcName: string): string => {
  return `https://us-central1-${FIREBASE_PROJECT_ID}.cloudfunctions.net/api/${funcName}`;
};

// NOTE: This is just for reference.
// export const callFunction = (funcName: string) => {
//   const callData = `rssUrl=${encodeURIComponent('https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml')}`;

//   axios.get(`${getCloudFunctionUrl(funcName)}?${callData}`)
//     .then((responseData) => {
//       return responseData.data;
//     }).catch((e) => {
//       return null;
//     });
// };
