import { FunctionComponent } from 'react';
import { Container } from '../../pieces/container';

import { Image } from '../../pieces/image';
import { Link } from 'react-router-dom';

type PropsType = {
  title: string;
  description?: string;
  imgSrc?: string;
  linkUrl: string;
  dateString?: string;
};

export const BlogListEntry: FunctionComponent<PropsType> = ({
  title,
  description,
  imgSrc,
  linkUrl,
  dateString,
}) => {
  return (
    <Container className="blog-list-entry">
      <Link
        className="link blog-list-entry-container"
        to={linkUrl}
      >
        {imgSrc && (
          <Image
            src={imgSrc}
            alt={title}
            className="blog-list-entry-image"
          />
        )}

        <div>
          <div className="blog-list-entry-title">{title}</div>

          <div className="blog-list-entry-description">{description}</div>

          {dateString && (
            <div className="blog-list-entry-date">{dateString}</div>
          )}
        </div>
      </Link>
    </Container>
  );
};
