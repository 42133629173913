import { FunctionComponent, useEffect } from 'react';
import { Container } from '../../pieces/container';
import { WorkList } from '../../pieces/work-list';
import { Image } from '../../pieces/image';
import { WorkHeader } from '../../pieces/work-header';
import { AppLink, AppType } from '../../pieces/app-link';
import { Video } from '../../pieces/video';

import headerIcon from './icon.png';
import carAirborneImage from './mcgraths-offroad-car-0-smaller.png';
import launchTrailer from './Jeremy-McGraths-Offroad-Launch-Trailer.mp4';
import launchTrailerPoster from './Jeremy-McGraths-Offroad-Launch-Trailer-poster.png';
import { WebLink } from '../../pieces/web-link';

const contributions = [
  'Tested and addressed issues related to the technical requirements checklist for digital distribution on the Xbox 360 and PlayStation 3.',
  'Updated content authoring tools and code base so the UI could be traversed with a game controller.',
  'Implemented system of challenges that rewards the player with upgrade points that improve vehicle performance.',
  'Implemented UI flow.',
  'Setup audio triggers for button-press feedback.',
];

export const WorksMcGrathsOffroad: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Jeremy McGrath's Offroad | Joe Gosselin";
 }, []);

  return (
    <div>
      <Container>
        <WorkHeader
          iconPath={headerIcon}
          header="Jeremy McGrath's Offroad"
          details={[
            'Developer: 2XL Games, Inc.',
            'Publisher: Reverb Communications Inc',
            'Released: 2012',
            'Platforms: Xbox360 and PS3',
          ]}
          footer={(
            <>
              <AppLink
                appType={AppType.xboxOnline}
                url="https://marketplace.xbox.com/en-US/Product/Jeremy-McGraths-Offroad/66acd000-77fe-1000-9115-d802584111da"
              />

              <AppLink
                appType={AppType.playStationNetwork}
                url="https://web.archive.org/web/20200811075620/https://www.playstation.com/en-us/games/jeremy-mcgraths-offroad-ps3/"
              />

              <AppLink
                appType={AppType.site}
                siteText="Official Site"
                url="https://web.archive.org/web/2018*/http://2xlgames.com/jmor/"
              />
            </>
          )}
        />
      </Container>

      <Container>
        <WorkList
          header="Contributions"
          list={contributions}
        />
      </Container>

      <Container centerText>
        <Video
          // https://www.youtube.com/watch?v=mRAeKcaBzNQ
          src={launchTrailer}
          caption="Launch trailer"
          poster={launchTrailerPoster}
        />
      </Container>

      <Container centerText>
        <Image
          src={carAirborneImage}
        />
      </Container>

      <Container>
        <span>Reviews:</span>

        <ul>
          <li>
            XBLA Fans - "Jeremy McGrath’s Offroad in the dirt on XBLA with new screenshots" - March 13, 2012&nbsp;
            <WebLink
              linkText="link"
              url="https://xblafans.com/jeremy-mcgraths-offroad-in-the-dirt-on-xbla-with-new-screenshots-41916.html"
            />
          </li>

          <li>Push Square - "Jeremy McGrath's Offroad Review" - March 27, 2013&nbsp;
            <WebLink
              linkText="link"
              url="https://www.pushsquare.com/reviews/psn/jeremy_mcgraths_offroad"
            />
          </li>
        </ul>
      </Container>
    </div>
  );
};
